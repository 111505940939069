import React, { useRef, useState, useEffect } from 'react';
import Upload from '../../../Assets/images/icons/upload.svg';
import Profile from '../../../Assets/images/icons/profile.svg';
import Back from '../../../Assets/images/icons/mobile/back.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useNavigate } from 'react-router-dom';
import api, { apiUrl } from '../../../Redux/api';
import { getMe, getUser, setError } from '../../../Redux/User/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Notify from '../../../Components/Toast';
import { Link } from 'react-router-dom';
import Avt from '../../../Assets/images/user.png';
import LoadingFull from '../../../Components/Loading/LoadingFull';

import Auth from '../../../Components/Google/Auth';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import GoogleCode from '../../../Components/Google/Code';
import axios from 'axios';
import AvatarModal from '../../../Components/Modal/AvatarModal';
import EmailModal from '../../../Components/Modal/EmailModal';

const Create = () => {
  const [image, setImage] = useState();
  const [file, setFile] = useState();
  const [openAvatar, setOpenAvatar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openGoogle, setOpenGoogle] = useState(false);
  const [googleCode, setGoogleCode] = useState('');
  const [openEmail, setOpenEmail] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const imgRef = useRef();

  const { error, data, info } = useSelector((state) => state.userSlice);
  const { wallet } = useSelector((state) => state.walletSlice);
  const { t } = useTranslation();

  const [userProfileApi, {}] = api.useSaveProfileMutation();
  const [apiInfo, {}] = api.useGetMeMutation();
  const [addEmailApi, {}] = api.useUpdateEmailMutation();
  const [verifyEmailApi, {}] = api.useVerifiedEmailMutation();
  // const [userProfileApi, {}] = api.useSaveProfileMutation();

  // useEffect(() => {
  //   // const config = genConfig({});

  //   // setImage(config);
  // }, []);

  const schema = z.object({
    userName: z
      .string()
      .min(4, { message: t('profile.name_error') })
      .max(50, { message: t('profile.name_error') }),
    email: z.string(),
    description: z.string().trim(),
    // skills: z.array(z.string()),
  });

  useEffect(() => {
    if (error) {
      Notify({ message: error, type: 'error', duration: 3000 });
    }
  }, [error]);

  const {
    register,
    handleSubmit,
    reset,
    getValues,

    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const saveProfile = async (values) => {
    try {
      if (values.email.length > 0) {
        Notify({ message: 'Please verify your email', type: 'error', duration: 3000 });
        return;
      }
      // setLoading(true);

      let img = image;
      if (file) {
        // upload
        let formData = new FormData();

        formData.append('avatar', file);
        const token = window.localStorage.getItem('auth_login');
        const token2 = window.localStorage.getItem('auth_login_');

        const response = await axios.post(`${apiUrl}/user/avatar`, formData, {
          headers: {
            accessToken: token,
            refreshToken: token2,
          },
        });

        img = response.data.data.file.path;
      }

      if (values.email && values.email.length > 0) {
        await addEmailApi({ email: values.email }).unwrap();
      }
      await userProfileApi({
        base_token: `open_${wallet}_hatch`,
        code: '000000',

        info: {
          username: values.userName,
          email: values.email,
          description: values.description,
          // config: image,
          img,
        },
      }).unwrap();

      reset();
      setGoogleCode('');

      await getUserInfo();

      Notify({ message: t('profile.edit_success'), type: 'success', duration: 3000 });

      setTimeout(() => {
        navigate('/panel/profile');
      }, 500);
      // await handelActions();
    } catch (err) {
      setLoading(false);
      const temp = error.data.error ? error.data.error[0].message : error.message;
      setGoogleCode('');
      dispatch(setError({ error: temp }));

      // Notify({ message: temp, type: 'error', duration: 3000 });
    }
  };

  const getUserInfo = async () => {
    try {
      // login user if not
      const token = window.localStorage.getItem('auth_login');
      const token2 = window.localStorage.getItem('auth_login_');

      if (token && token2) {
        const response = await apiInfo().unwrap();

        dispatch(getMe({ user: response.data.user }));
      }

      //
      setLoading(false);
    } catch (error) {
      // console.log(error);

      const temp = error.data.error ? error.data.error[0].message : error.message;

      dispatch(setError({ error: temp }));
      setLoading(false);
      // navigate('/',);
    }
  };

  const imageUpload = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];

    // validate size
    const size = file.size / 1024;

    if (size > 256) {
      Notify({ message: 'Size of image cannot be more than 256 kb', type: 'error', duration: 3000 });
      return;
    }

    // get type

    if (!file.type.includes('image')) {
      Notify({ message: 'Please add a image', type: 'error', duration: 3000 });
      return;
    }

    const objectUrl = URL.createObjectURL(e.target.files[0]);

    setImage(objectUrl);
    setFile(file);
  };

  const verifyEmailHandler = async (code) => {
    try {
      setOpenEmail(false);
      setLoading(true);
      await verifyEmailApi({ code: code }).unwrap();
      Notify({ message: 'Email verified', type: 'success', duration: 3000 });
      getUserInfo();
    } catch (error) {
      // console.log(error);
      setLoading(false);
      const temp = error.data.error ? error.data.error[0].message : error.message;

      dispatch(setError({ error: temp }));
    }
  };

  return (
    <div className='w-full h-full py-6 md:py-16 px-4 md:px-8 text-neutral'>
      {info && info.user.qrToken ? (
        <Auth
          open={open}
          qr={info.user.qrToken}
          img={info.user.qrCode}
          enable={info.user.reqAuth}
          wallet={wallet}
          next={() => {
            setOpen(false);
          }}
        />
      ) : null}
      <EmailModal open={openEmail} cancelBtn={() => setOpenEmail(false)} confirmBtn={verifyEmailHandler} />
      {info && (
        <AvatarModal
          open={openAvatar}
          addAvatar={async (val) => {
            setOpenAvatar(false);
            setImage(val);
          }}
          cancelBtn={() => setOpenAvatar(false)}
          avatar={''}
        />
      )}

      <GoogleCode
        open={openGoogle}
        close={() => {
          setGoogleCode('');
          setOpenGoogle(false);
        }}
        next={(code) => {
          setGoogleCode(code);
          setOpenGoogle(false);
          setTimeout(() => {
            if (formRef.current) {
              formRef.current.click();
            }
          }, 500);
        }}
      />

      {loading ? (
        <LoadingFull />
      ) : (
        <div className='card  bg-transparent text-neutral'>
          <div className='card-body'>
            <div className='flex items-center mb-8'>
              <Link to='/panel/profile' className='lg:hidden'>
                <img src={Back} alt='back' />
              </Link>
              <h2 className='card-title pl-4 lg:pl-0'>{t('profile.edit')}</h2>
            </div>
            <input onChange={imageUpload} ref={imgRef} type='file' className='hidden' />
            <div className='flex justify-between'>
              <div className='indicator'>
                <span
                  onClick={() => {
                    // if (imgRef.current) {
                    //   imgRef.current.click();
                    // }
                    setOpenAvatar(true);
                  }}
                  className='indicator-item badge badge-success cursor-pointer'>
                  Add
                </span>
                <div className='avatar border-2 shadow-lg shadow-info border-info rounded-full   w-max mx-auto md:mx-0 mb-8'>
                  <div className='w-24 rounded-full'>
                    <img src={image ? `${apiUrl}/${image}` : Avt} alt='avatar' />
                  </div>
                </div>
              </div>

              {/* <FontAwesomeIcon
                onClick={() => {
                  setOpen(true);
                }}
                icon={faQrcode}
                size='3x'
                border
                className=' text-neutral cursor-pointer  rounded-2xl'
              /> */}
            </div>

            <form onSubmit={handleSubmit(saveProfile)}>
              <div className='grid grid-cols-1 gap-4 text-neutral'>
                <div className='w-full'>
                  <div className='form-control w-full max-w-xl'>
                    <label className='label'>
                      <span className='label-text'>{t('profile.name')}:</span>
                    </label>

                    <div className='relative'>
                      <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
                        <img src={Profile} alt='Profile' />
                      </div>
                      <input
                        type='text'
                        placeholder='Type here...'
                        className='input input-bordered w-full bg-secondary input-info pl-10'
                        {...register('userName')}
                      />
                    </div>

                    <label className='label'>
                      <span className='label-text text-warning'>
                        Your username is not publicly shown to anyone.
                      </span>
                      {errors.userName?.message && (
                        <span className='label-text-alt text-error'>{errors.userName?.message}</span>
                      )}
                    </label>
                  </div>
                </div>

                <div className='w-full'>
                  <div className='form-control w-full max-w-xl'>
                    <label className='label'>
                      <span className='label-text'>Your Email (Optional):</span>
                    </label>

                    <div className='relative flex'>
                      <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
                        <svg
                          aria-hidden='true'
                          className='w-5 h-5 text-gray-500 dark:text-gray-400'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'>
                          <path d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z'></path>
                          <path d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z'></path>
                        </svg>
                      </div>
                      <input
                        type='email'
                        placeholder='name@gmail.com'
                        className='input input-bordered w-full bg-secondary input-info pl-10'
                        {...register('email')}
                      />

                      {info ? (
                        <button
                          onClick={async () => {
                            try {
                              const email = getValues().email;
                              if (!email || email.length <= 0) {
                                return;
                              }
                              setOpenEmail(true);
                              Notify({
                                message: 'Verification Code send to your email',
                                type: 'success',
                                duration: 3000,
                              });
                              await addEmailApi({ email: getValues().email }).unwrap();
                            } catch (error) {
                              const temp = error.data.error ? error.data.error[0].message : error.message;

                              dispatch(setError({ error: temp }));
                            }
                          }}
                          className='btn btn-primary w-20 ml-2   btn-outline  hover:bg-transparent  disabled:bg-secondary'>
                          Verify
                        </button>
                      ) : null}
                    </div>
                    <label className='label'>
                      {errors.email?.message && (
                        <span className='label-text-alt text-error'>{errors.email?.message}</span>
                      )}
                    </label>
                  </div>
                </div>

                {/* <div className='w-full'>
                  <div className='form-control w-full max-w-xl'>
                    <label className='label'>
                      <span className='label-text'>Your Skills:</span>
                    </label>
                    <MultiSelect
                      options={option}
                      value={selected}
                      onChange={setSelected}
                      labelledBy='Select'
                      className='bg-secondary border border-info rounded-lg'
                    />
                  </div>
                </div> */}

                <div className='w-full'>
                  <div className='form-control w-full max-w-xl'>
                    <label className='label'>
                      <span className='label-text'>{t('profile.desc')}:</span>
                    </label>

                    <textarea
                      className='textarea textarea-info bg-secondary w-full'
                      placeholder='Bio'
                      {...register('description')}></textarea>

                    <label className='label'>
                      {errors.description?.message && (
                        <span className='label-text-alt text-error'>{errors.description?.message}</span>
                      )}
                    </label>
                  </div>
                </div>
                <div className='card-actions justify-start'>
                  <button
                    ref={formRef}
                    className={`btn btn-primary rounded-2xl  no-animation  w-full md:w-48`}>
                    {t('profile.save')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create;
