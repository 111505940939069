import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  selected: null,
  loading: false,
  error: null,
  fundraisingList: [],
  reload: false,
  cancelledList: [],
};

export const SubscribeSlice = createSlice({
  name: 'subscribe',
  initialState,
  reducers: {
    getAll: (state, actions) => {
      const { data } = actions.payload;

      state.list = data;
      state.loading = false;
      state.login = true;
      state.error = null;
      state.reload = false;
    },
    reloadData: (state, actions) => {
      state.reload = true;
    },
    getAllFund: (state, actions) => {
      const { data } = actions.payload;

      state.fundraisingList = data;
      state.loading = false;
      state.login = true;
      state.error = null;
      state.reload = false;
    },
    getAllCancelled: (state, actions) => {
      const { data } = actions.payload;

      state.cancelledList = data;
      state.loading = false;
      state.login = true;
      state.error = null;
      state.reload = false;
    },
    getOne: (state, actions) => {
      const data = actions.payload;

      state.selected = data;
      state.loading = false;
      state.login = true;
      state.error = null;
      state.reload = false;
    },
    setError: (state, actions) => {
      const { error } = actions.payload;
      console.log(actions.payload);
      state.loading = false;
      state.login = false;
      state.reload = false;
      state.error = error;
    },
    setLoading: (state) => {
      state.loading = true;
    },
  },
});

export const { getAll, getOne, setError, setLoading, getAllFund, reloadData, getAllCancelled } =
  SubscribeSlice.actions;

export default SubscribeSlice.reducer;
