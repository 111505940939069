import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  login: false,
  loading: false,
  error: null,
  auth: null,
  info: null,
  skillsList: [],
  stats: null,
  notification: null,
  selected: null,
  theme: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, actions) => {
      const data = actions.payload;

      state.data = data;
      state.loading = false;
      state.login = true;
      state.error = null;
    },
    setError: (state, actions) => {
      const { error } = actions.payload;
      state.loading = false;
      // state.login = false;
      state.error = error;
    },
    addStats: (state, actions) => {
      state.stats = actions.payload;
    },
    addNotification: (state, actions) => {
      state.notification = actions.payload;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    getUser: (state, actions) => {
      const { user } = actions.payload;

      if (user.data.accessToken) {
        window.localStorage.setItem('auth_login', user.data.accessToken);
        window.localStorage.setItem('auth_login_', user.data.refreshToken);
      }

      state.loading = false;
      state.login = false;
      state.error = null;
      state.user = user;
      state.data = null;
    },
    getMe: (state, actions) => {
      const { user } = actions.payload;

      state.loading = false;
      state.login = false;
      state.error = null;
      state.info = user;
      state.data = null;
    },

    addSkills: (state, actions) => {
      const { skills } = actions.payload;

      state.loading = false;
      state.skillsList = skills;
      state.error = null;

      state.data = null;
    },
    addSelected: (state, actions) => {
      localStorage.setItem('mode', actions.payload);
      state.selected = actions.payload;
    },
    addTheme: (state, actions) => {
      state.theme = actions.payload;
    },
    logout: (state) => {
      window.localStorage.removeItem('auth_login');
      window.localStorage.removeItem('auth_login_');
      window.localStorage.removeItem('mode');

      window.location = '/';
      state.login = false;
      state.error = null;
      state.user = null;
      state.info = null;
    },
  },
});

export const {
  getUser,
  loginUser,
  logout,
  setError,
  setLoading,
  getMe,
  addSkills,
  addStats,
  addNotification,
  addSelected,
  addTheme,
} = userSlice.actions;

export default userSlice.reducer;
