import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../Redux/api';
import { getMe } from '../../Redux/User/slice';
const Auth = ({ open, qr, img, next, enable, wallet, rm }) => {
  const [up, setUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const openRef = useRef();
  const [userAuth, {}] = api.useEnableAuthMutation();
  const [apiInfo, {}] = api.useGetMeMutation();

  const dispatch = useDispatch();
  useEffect(() => {
    // console.log(openRef.current);
    // if (openRef.current) {

    if (open && up === false) {
      setUp(true);
      openRef.current.click();
    }

    if (open === false && up) {
      setUp(false);

      openRef.current.click();
    }

    // }
  }, [open, up]);
  return (
    <div>
      <label htmlFor='my-modal-3' className='btn modal-button hidden'>
        open modal
      </label>

      <input ref={openRef} type='checkbox' id='my-modal-3' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box'>
          <label
            onClick={() => {
              if (loading) {
                return;
              }
              next();
            }}
            class='btn btn-sm btn-error btn-circle absolute right-2 top-2'>
            ✕
          </label>

          <h2 className='text-warning pb-4'>
            {enable
              ? ''
              : 'To enable 2 way authentication for your account, please Scan the QR Code with your mobile device using Google Authenticator.'}
          </h2>
          <img
            id='google_qr'
            className='mx-auto my-3 rounded-xl shadow-lg'
            src={img}
            width='250'
            height='250'
            alt=''
          />

          <div class='w-full '>
            <div className='w-full my-4'>
              <div className='form-control w-full max-w-xl'>
                <input
                  type='text'
                  placeholder='Code'
                  className='input input-bordered w-full bg-secondary input-info pl-10'
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={async () => {
                if (code.length === 6) {
                  try {
                    setLoading(true);
                    let data = {
                      base_token: `open_${wallet}_hatch`,
                      code: code,
                      delete_auth: false,
                      enable: false,
                    };
                    if (enable) {
                      data.delete_auth = true;
                      data.enable = false;
                    } else {
                      // if (rm) {
                      //   await userAuth({
                      //     base_token: `open_${wallet}_hatch`,
                      //     code: code,
                      //     delete_auth: true,
                      //     enable: true,
                      //   }).unwrap();
                      // }
                    }

                    // console.log({ data });

                    await userAuth(data).unwrap();
                    const response = await apiInfo().unwrap();

                    dispatch(getMe({ user: response.data.user }));
                    setLoading(false);
                    // next();
                  } catch (error) {
                    // console.log(error);
                    setLoading(false);
                  }
                }
              }}
              class={`btn mx-auto btn-primary rounded-2xl btn_main_color no-animation w-full  ${
                loading && 'loading'
              }`}>
              {!enable ? 'Enable' : 'Disable'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
