// ------------imports---------------
import axios from 'axios';
// ------------end imports-----------
import { encrypt, decrypt } from '../utils/encryption';

const statusData = true;
// export const addHeader = (token) => {
//   if (token) {
//     axios.defaults.headers.common['Authorization'] = token;
//   } else {
//     delete axios.defaults.headers.common['Authorization'];
//   }
// };

export const axiosSetup = () => {
  axios.interceptors.request.use(
    async (config) => {
      if (statusData) {
        if (config.data && Object.keys(config.data).length > 0) {
          config.data = {
            data: encrypt(config.data),
          };
        }
      }

      // console.log({ req: config.data });
      return config;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      if (statusData) {
        const dataDecrypted = decrypt(response.data.data);

        response.data = dataDecrypted;
      }
      // console.log({ res: response.data });
      return response;
    },
    (error) => {
      if (statusData) {
        const dataDecrypted = decrypt(error.response.data.data);

        error.response.data = dataDecrypted;
      }

      // console.log({ res: error.response.data });

      return Promise.reject(error);
    }
  );
};
