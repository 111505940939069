import axios from 'axios';
import Web3 from 'web3';
import PTABI from '../Assets/files/abi2.json';
import APABI from '../Assets/files/abiToken.json';
import { signMessage } from '../config/walletConnect';
import fromExponential from './helper';

const contract_tx_pt = '0x14966439fF3a360F25c2682b2462C4FdE93E4e7E';
// const contract_tx = '0xdAC17F958D2ee523a2206206994597C13D831ec7'; // usdt

const code = 'qaz123'; // 'qaz123'; // 'e28d00a7';

let web3;

let ErcContractPt;
let ErcContractAP;
let pv;

// export const getPrice = async () => {
//   try {
//     const responsePrice = await axios.get(
//       'https://api.ethgasstation.info/api/fee-estimate?api-key=d36544bbaa4ef780c0024ba0a827c86865446615ee38bd83157e364dc7ee'
//     );

//     const price = responsePrice.data.baseFee / 1;

//     return web3.utils.toWei(price.toString(), 'Gwei');
//   } catch (error) {
//     const price = await web3.eth.getGasPrice();
//     return price;
//   }
// };

export const addProvider = (provider) => {
  pv = provider;

  addPv(provider);
};

const addPv = async () => {
  web3 = new Web3(pv);
  web3.currentProvider = web3.givenProvider;
  ErcContractPt = new web3.eth.Contract(PTABI, contract_tx_pt);
};

const addContract = async (name) => {
  ErcContractAP = new web3.eth.Contract(APABI, name);

  // console.log({ balance });
};

export const removeProvider = () => {
  web3 = null;
};

export const getChain = async () => {
  try {
    const res = await web3.eth.getChainId();

    return res;
  } catch (error) {
    // console.log(error);
    return -1;
  }
};

export const checkSubmit = async (id) => {
  if (!web3) {
    await addPv();
  }

  try {
    const res = await ErcContractPt.methods.get_openHatck(id).call();

    return res.id.length > 0 ? true : false;
  } catch (error) {
    return false;
  }
};

export const getBalance = async (wallet, name) => {
  if (!web3) {
    await addPv();
  }

  await addContract(name);

  // console.log('here');
  const balance = await ErcContractAP.methods.balanceOf(wallet).call();

  // console.log({ balance });

  return balance;
};

export const getFeeOfToken = async (prePrice) => {
  try {
    if (!web3 || !ErcContractPt) {
      await addPv();
    }

    const res = await ErcContractPt.methods.get_submitFee().call();

    return res / 1000;
  } catch (error) {
    // console.log('err');
    // console.log(error);
    return '1';
  }
};

const getDecimals = async () => {
  const res = await ErcContractAP.methods.decimals().call();

  const zeroPad = String(1).padEnd(Number(res) + 1, '0');

  return Number(zeroPad);
};

export const approveProposal = async (address, amount, name, isJudge = false) => {
  try {
    // console.log('here');
    if (!web3) {
      await addPv();
    }

    await addContract(name);

    const decimal = await getDecimals();

    // console.log({ decimal });
    if (isJudge === false) {
      const per = await getFeeOfToken(amount);

      // console.log({ per });
      amount = (Number(amount) + Math.round(Number(per) * Number(amount)) / 100).toString();
    }

    const result = await checkApprove(address, amount, decimal);

    if (result === true) {
      return {
        success: true,
      };
    }

    // console.log({ before: amount });

    amount = (Number(amount.toString()) * decimal).toString();

    // console.log({ amount });

    amount = fromExponential(amount).toString();
    const gas = await web3.eth.estimateGas({
      from: address,
      // to: contract_tx_pt,
    });

    const transaction1 = await ErcContractAP.methods.approve(contract_tx_pt, amount).send({ from: address, gas });

    return {
      success: true,
    };
  } catch (error) {
    // console.log({ error });
    return {
      success: false,
      error: error.message,
    };
  }
};

export const checkApprove = async (address, value, decimal) => {
  try {
    const transaction1 = await ErcContractAP.methods.allowance(address, contract_tx_pt).call();
    let amount = (Number(transaction1.toString()) / decimal).toString();
    // console.log({ amount });
    amount = fromExponential(amount).toString();
    if (Number(amount) < Number(value)) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const submitProposal = async ({
  address,
  _title,
  _budget,
  _slot_budgetArray,
  _slot_paidArray,
  _descripotion,
  _author,
  _acceptor,
  _id,
  _start_date,
  _due_date,
  _count_timelines,
  name,
}) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);

    const per = await getFeeOfToken(_budget);

    _budget = (Number(_budget) + Math.round(Number(per) * Number(_budget)) / 100).toString();

    const decimal = await getDecimals();

    const balance = await ErcContractAP.methods.balanceOf(address).call();
    let amount = (Number(balance) / decimal).toString();

    amount = fromExponential(amount).toString();
    if (Number(amount) < Number(_budget)) {
      return {
        success: false,
        error: 'Not enough balance',
      };
    }

    let value = (Number(_budget.toString()) * decimal).toString();

    value = fromExponential(value).toString();
    let list = [];
    _slot_budgetArray.map((val) => {
      const temp = (Number(val.toString()) * decimal).toString();
      list.push(fromExponential(temp).toString());
    });

    // console.log([_author, _acceptor, name], value, list, _slot_paidArray, _id, _due_date);

    var buyItem = ErcContractPt.methods.submit_openHatch([_author, _acceptor, name], value, list, _slot_paidArray, _id, _due_date);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    // console.log({ gas_estimate });

    let res = await ErcContractPt.methods.submit_openHatch([_author, _acceptor, name], value, list, _slot_paidArray, _id, _due_date).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });
    // console.log({ res });
    return {
      success: true,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const submitJudge = async ({ address, _budget, _id, name }) => {
  try {
    if (!web3) {
      await addPv();
    }

    // console.log('start submit');

    await addContract(name);
    // console.log(web3);
    const balance = await ErcContractAP.methods.balanceOf(address).call();

    const decimal = await getDecimals();

    let amount = (Number(balance) / decimal).toString();

    amount = fromExponential(amount).toString();

    if (Number(amount) < Number(_budget)) {
      return {
        success: false,
        error: 'Not enough balance',
      };
    }

    let value = (Number(_budget.toString()) * decimal).toString();

    value = fromExponential(value).toString();
    // console.log(_id, code, value, name);

    var buyItem = ErcContractPt.methods.add_judge(_id, code, value, name);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.add_judge(_id, code, value, name).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const addStepPay = async ({ _index, _id, address }) => {
  try {
    if (!web3 || !ErcContractPt) {
      await addPv();
    }

    var buyItem = ErcContractPt.methods.add_pay(_index, _id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.add_pay(_index, _id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });
    // console.log({ res });
    return {
      success: true,
      data: res,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const addStepsPay = async ({ _index, _id, address }) => {
  try {
    if (!web3 || !ErcContractPt) {
      await addPv();
    }

    var buyItem = ErcContractPt.methods.add_pays(_index, _id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.add_pays(_index, _id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });
    // console.log({ res });
    return {
      success: true,
      data: res,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const closeProposal = async ({ _id, address }) => {
  try {
    if (!web3 || !ErcContractPt) {
      await addPv();
    }

    var buyItem = ErcContractPt.methods.close_openHatch(_id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.close_openHatch(_id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });
    // console.log({ res });
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const signProposal = async (fromAddress, value) => {
  try {
    if (!web3) {
      await addPv();
    }

    if (!web3.currentProvider) {
      await addPv();
    }
    let res = {
      success: false,
    };
    if (web3) {
      const msgParams = JSON.stringify({
        domain: {
          // Defining the chain aka Rinkeby testnet or Ethereum Main Net
          chainId: 1,
          // Give a user friendly name to the specific contract you are signing for.
          name: 'Sign Proposal',
          // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
          verifyingContract: contract_tx_pt,
          // Just let's you know the latest version. Definitely make sure the field name is correct.
          version: '1',
        },

        // Defining the message signing data content.
        message: {
          /*
           - Anything you want. Just a JSON Blob that encodes the data you want to send
           - No required fields
           - This is DApp Specific
           - Be as explicit as possible when building out the message schema.
          */
          contents: 'Sign Proposal',
          attachedMoneyInEth: value,
          from: {
            name: 'user',
            wallets: [fromAddress],
          },
          to: [
            {
              name: 'contract',
              wallets: [contract_tx_pt],
            },
          ],
        },
        // Refers to the keys of the *types* object below.
        primaryType: 'Mail',
        types: {
          // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
          EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'chainId', type: 'uint256' },
            { name: 'verifyingContract', type: 'address' },
          ],
          // Not an EIP712Domain definition
          Group: [
            { name: 'name', type: 'string' },
            { name: 'members', type: 'Person[]' },
          ],
          // Refer to PrimaryType
          Mail: [
            { name: 'from', type: 'Person' },
            { name: 'to', type: 'Person[]' },
            { name: 'contents', type: 'string' },
          ],
          // Not an EIP712Domain definition
          Person: [
            { name: 'name', type: 'string' },
            { name: 'wallets', type: 'address[]' },
          ],
        },
      });

      var params = [fromAddress, msgParams];
      var method = 'eth_signTypedData_v4';

      const store = localStorage.getItem('wallet');

      if (store) {
        const parse = JSON.parse(store);

        if (parse.type === '2') {
          const response = await signMessage(method, params);
          if (response === null) {
            return {
              success: false,
              error: 'error',
            };
          }

          return {
            success: true,
            data: response,
          };
        } else {
          await new Promise((resolve) => {
            web3.currentProvider.sendAsync(
              {
                method,
                params,
                from: fromAddress,
              },
              function (err, result) {
                if (err) {
                  res = {
                    success: false,
                    error: err,
                  };
                  resolve();
                  return;
                }
                if (result.error) {
                  res = {
                    success: false,
                    error: result.error,
                  };
                  resolve();
                  return;
                }

                res = {
                  success: true,
                  data: result.result,
                };
                resolve();
                return;
              }
            );
          });
        }
      }
    }
    return res;
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const submitSubscribeCreateBox = async ({ address, _budget, _id, name, box_id, monthly }) => {
  try {
    if (!web3) {
      await addPv();
    }

    // console.log({ box_id });
    // console.log({ monthly });

    await addContract(name);
    // console.log(web3);

    const decimal = await getDecimals();

    let value = (Number(_budget.toString()) * decimal).toString();

    var buyItem = ErcContractPt.methods.submitAsSubscribe(value, _id, box_id, name, monthly);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    value = fromExponential(value).toString();
    let res = await ErcContractPt.methods.submitAsSubscribe(value, _id, box_id, name, monthly).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const submitSubscribe = async ({ address, _budget, _id, name }) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);
    // console.log(web3);
    // const balance = await ErcContractAP.methods.balanceOf(address).call();

    // const decimal = await getDecimals();
    // let amount = (Number(balance) / decimal).toString();

    // amount = fromExponential(amount).toString();
    // if (Number(amount) < Number(_budget)) {
    //   return {
    //     success: false,
    //     error: 'Not enough balance',
    //   };
    // }

    // let value = (Number(_budget.toString()) * decimal).toString();

    var buyItem = ErcContractPt.methods.requestSubscribe(_id, name);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.requestSubscribe(_id, name).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const submitSubscribeBox = async ({ address, _budget, _id, name, box_id }) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);
    // console.log(web3);
    const balance = await ErcContractAP.methods.balanceOf(address).call();
    const decimal = await getDecimals();
    let amount = (Number(balance) / decimal).toString();

    amount = fromExponential(amount).toString();
    if (Number(amount) < Number(_budget)) {
      return {
        success: false,
        error: 'Not enough balance',
      };
    }

    let value = (Number(_budget.toString()) * decimal).toString();

    var buyItem = ErcContractPt.methods.submitSubscription(value, _id, box_id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    value = fromExponential(value).toString();
    let res = await ErcContractPt.methods.submitSubscription(value, _id, box_id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const withdrawSubscribe = async ({ address, _budget, _id, name, box_id }) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);
    const decimal = await getDecimals();
    let value = (Number(_budget.toString()) * decimal).toString();

    var buyItem = ErcContractPt.methods.WithdrawSubscrip(value, _id, box_id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    value = fromExponential(value).toString();
    let res = await ErcContractPt.methods.WithdrawSubscrip(value, _id, box_id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const returnSubscribeAmount = async ({ _id, box_id }) => {
  try {
    if (!web3) {
      await addPv();
    }

    let res = await ErcContractPt.methods.returnSubscribe(_id, box_id).call();

    await addContract(Object.values(res)[0].base_token);
    const decimal = await getDecimals();

    let amount = fromExponential((Number(Object.values(res)[1]) / decimal).toString()).toString();
    return {
      success: true,
      data: {
        balance: amount,
        result: Object.values(res)[0],
      },
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      data: {
        balance: '0',
      },
    };
  }
};

export const removeJudge = async ({ address, id }) => {
  try {
    if (!web3) {
      await addPv();
    }

    var buyItem = ErcContractPt.methods.remove_judge(id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.remove_judge(id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const withdrawReward = async ({ address, amount, name, id }) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);

    const decimal = await getDecimals();

    let value = (Number(amount) * decimal).toString();

    var buyItem = ErcContractPt.methods.withdrawReward(value, id, name);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    value = fromExponential(value).toString();
    let res = await ErcContractPt.methods.withdrawReward(value, id, name).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const tipPackage = async ({ address, _budget, name, id, author_address }) => {
  try {
    // console.log({ address, _budget, name, id, author_address });
    if (!web3) {
      await addPv();
    }

    await addContract(name);

    const decimal = await getDecimals();

    const balance = await ErcContractAP.methods.balanceOf(address).call();
    let amount = (Number(balance) / decimal).toString();

    amount = fromExponential(amount).toString();
    if (Number(amount) < Number(_budget)) {
      return {
        success: false,
        error: 'Not enough balance',
      };
    }

    let value = (Number(_budget.toString()) * decimal).toString();

    value = fromExponential(value).toString();

    var buyItem = ErcContractPt.methods.PutInBox(value, author_address, name);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.PutInBox(value, author_address, name).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const withdrawTip = async ({ address, amount, name, id }) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);

    const decimal = await getDecimals();

    let value = (Number(amount) * decimal).toString();
    var buyItem = ErcContractPt.methods.DropBox(value, name);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    value = fromExponential(value).toString();
    let res = await ErcContractPt.methods.DropBox(value, name).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const donateWeb3 = async ({ address, _budget, name, author_address, id }) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);

    const decimal = await getDecimals();

    const balance = await ErcContractAP.methods.balanceOf(address).call();
    let amount = (Number(balance) / decimal).toString();

    amount = fromExponential(amount).toString();
    if (Number(amount) < Number(_budget)) {
      return {
        success: false,
        error: 'Not enough balance',
      };
    }

    let value = (Number(_budget.toString()) * decimal).toString();

    value = fromExponential(value).toString();

    var buyItem = ErcContractPt.methods.addFund(value, author_address, name, id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.addFund(value, author_address, name, id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const withdrawDonate = async ({ address, amount, name, id }) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);

    const decimal = await getDecimals();

    let value = (Number(amount) * decimal).toString();
    value = fromExponential(value).toString();

    var buyItem = ErcContractPt.methods.DropFund(value, name, id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.DropFund(value, name, id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const cancelFund = async ({ address, id, name }) => {
  try {
    if (!web3) {
      await addPv();
    }

    // console.log({ address, id, name });
    var buyItem = ErcContractPt.methods.CancelFund(name, id);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.CancelFund(name, id).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};

export const withdrawCanceled = async ({ address, amount, name, id, author_address }) => {
  try {
    if (!web3) {
      await addPv();
    }

    await addContract(name);

    const decimal = await getDecimals();

    let value = (Number(amount) * decimal).toString();
    value = fromExponential(value).toString();

    // console.log({ value });
    var buyItem = ErcContractPt.methods.DropFund_users(value, name, id, author_address);
    var gas_estimate = await buyItem.estimateGas({ from: address });
    let res = await ErcContractPt.methods.DropFund_users(value, name, id, author_address).send({
      from: address, // default from address
      gas: web3.utils.toHex(gas_estimate),
    });

    return {
      success: true,
      data: res,
    };
  } catch (error) {
    // console.log(error);
    return {
      success: false,
      error: error.message,
    };
  }
};
