import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';
import {
  checkWalletConnectProvider,
  openWalletConnect,
  removeWalletConnect,
} from '../../config/walletConnect';

const initialState = {
  wallet: null,
  success: false,
  loading: false,
  error: null,
  hasEth: false,
  mobile: false,
  type: '',
};

const networks = {
  eth: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3/975a24f4b0df4237bfac690cef2ad74c'],
    blockExplorerUrls: ['https://etherscan.io'],
  },
};

export const connectToMetaMask = createAsyncThunk('wallet/metamask', async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networks.eth.chainId }],
    });
    const res = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    return {
      success: true,
      data: res[0],
    };
  } catch (error) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [networks.eth],
        });
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: networks.eth.chainId }],
        });
        const res = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });

        return {
          success: true,
          data: res[0],
        };
      } catch (err) {
        return {
          success: false,
          data: error.message,
        };
      }
    } else {
      // console.log('..............');
      // console.log(error);
      return {
        success: false,
        data: error.message,
      };
    }
  }
});

export const connectToWalletConnect = createAsyncThunk('wallet/wallet_connect', async () => {
  try {
    checkWalletConnectProvider();

    const res = await openWalletConnect();
    return {
      success: true,
      data: res[0],
    };
  } catch (error) {
    removeWalletConnect();
    return {
      success: false,
      data: error.message,
    };
  }
});

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    checkEth: (state) => {
      state.hasEth = window.ethereum ? true : false;
    },
    checkMobile: (state) => {
      state.mobile = isMobile;
    },
    disconnect: (state) => {
      window.localStorage.removeItem('wallet');
      state.success = false;
      state.wallet = null;
    },
    setErrorWallet: (state, actions) => {
      state.error = actions.payload.error;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectToMetaMask.fulfilled, (state, actions) => {
      const { success, data } = actions.payload;

      if (success) {
        window.localStorage.setItem(
          'wallet',
          JSON.stringify({
            wallet: data,
            type: '1',
          })
        );
      } else {
        window.localStorage.removeItem('wallet');
      }

      state.wallet = success ? data : null;
      state.error = !success ? data : null;
      state.type = 'Metamask';
      state.loading = false;
      state.success = success;
    });

    builder.addCase(connectToWalletConnect.fulfilled, (state, actions) => {
      const { success, data } = actions.payload;

      // check cha
      if (success) {
        window.localStorage.setItem(
          'wallet',
          JSON.stringify({
            wallet: data,
            type: '2',
          })
        );
      } else {
        window.localStorage.removeItem('wallet');
      }

      // console.log({ success, data });
      state.wallet = success ? data : null;
      state.type = 'WalletConnect';
      state.error = !success ? data : null;
      state.loading = false;
      state.success = success;
    });
  },
});

export const { checkMobile, checkEth, disconnect, setErrorWallet } = walletSlice.actions;

export default walletSlice.reducer;
