import CryptoJS from 'crypto-js';

let key_1 = 'ABjh#aASD!#$!@#@546saD!#$%$#98';
let key_2 = '83276nasd1!@#*&^!@#&*2dsafgASDF12$!$!s%%gd';

export const encrypt = (data) => {
  /**
   *	Description	encrypt all request to server
   *
   */
  var encrypt_1 = CryptoJS.AES.encrypt(JSON.stringify(data), key_1).toString(); //crypt 1
  let encrypt_2 = CryptoJS.RC4.encrypt(encrypt_1, key_2).toString(); //crypt 2

  return encrypt_2;
};

export const decrypt = (data) => {
  /**
   *	Description	decrypt all server response
   *
   */

  var original_encrypt_1 = CryptoJS.RC4.decrypt(data, key_2).toString(CryptoJS.enc.Utf8);
  var originalText = CryptoJS.AES.decrypt(original_encrypt_1, key_1).toString(CryptoJS.enc.Utf8);

  //result
  // return data;

  return JSON.parse(originalText);
};
