import { faFileInvoice, faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addSelected } from '../../Redux/User/slice';

const ChoseModeModal = ({ open, cancelBtn }) => {
  const [up, setUp] = useState(false);
  const [selected, setSelected] = useState('1');
  const openRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (open && up === false) {
      setUp(true);
      openRef.current.click();
    }

    if (open === false && up) {
      setUp(false);

      openRef.current.click();
    }

    // }
  }, [open, up]);

  return (
    <div>
      <label htmlFor='my-modal' className='btn modal-button hidden'>
        open modal
      </label>

      <input ref={openRef} type='checkbox' id='my-modal' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box w-11/12 max-w-3xl bg-secondary'>
          {/* <label
            onClick={() => {
              cancelBtn();
            }}
            className='btn btn-sm btn-error btn-circle absolute right-2 top-2'>
            ✕
          </label> */}

          <div className=''>
            <div className='flex-1'>
              <div className='text-center'>
                <h2 className='text-3xl font-bold text-center text-primary '>Welcome!</h2>
              </div>

              <div className='mt-8'>
                <ul className='menu bg-transparent w-full p-2 rounded-box'>
                  <li
                    className='border border-primary  transition ease-in-out delay-75 duration-300 hover:bg-transparent  hover:shadow-custom  '
                    style={{ backgroundColor: 'rgba(0, 194, 255, 0.3)' }}>
                    <a
                      onClick={() => {
                        setSelected('1');
                        dispatch(addSelected('1'));
                        // window.location.reload();
                        cancelBtn();
                        navigate('/panel/profile');
                      }}
                      className=' hover:bg-transparent '>
                      <FontAwesomeIcon
                        icon={faFileInvoice}
                        size='xl'
                        className=' text-info  no-animation ml-2 '
                      />
                      <p className=' text-neutral'>OpenHatch</p>
                      {/* {selected === '1' && (
                        <div className='badge badge-warning badge-lg ml-auto'>Selected</div>
                      )} */}
                    </a>
                  </li>
                  <li className='border border-primary  transition ease-in-out delay-75 duration-300 hover:bg-transparent  hover:shadow-custom  '>
                    <a
                      onClick={() => {
                        setSelected('2');
                        dispatch(addSelected('2'));
                        cancelBtn();
                        // window.location.reload();
                        navigate('/panel/profile');
                      }}
                      className=' hover:bg-transparent '>
                      <FontAwesomeIcon icon={faGavel} size='xl' className=' text-info  no-animation ml-2 ' />
                      <p className=' text-neutral'>Validator portal</p>
                      {/* {selected === '2' && (
                        <div className='badge badge-warning badge-lg ml-auto'>Selected</div>
                      )} */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoseModeModal;
