import toast from 'react-hot-toast';

const Notify = ({ message, type, duration }) => {
  if (type === 'success') {
    return toast.success(message, { duration });
  }
  return toast.error(message, { duration });
};

export default Notify;
