import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-');
}

const DateModal = ({ setOpen, open, setModalData, modalData }) => {
  const openRef = useRef();
  const [up, setUp] = useState(false);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);
  useEffect(() => {
    if (open === false && up === true) {
      openRef.current.click();
      setUp(false);
    }

    if (open === true && up === false) {
      openRef.current.click();
      setUp(true);
    }
  }, [open, up]);

  useEffect(() => {
    if (state.length > 0 && state[0].endDate) {
      setModalData({ ...modalData, dates: [formatDate(state[0].startDate), formatDate(state[0].endDate)] });
    }
  }, [state]);

  return (
    <div>
      <label htmlFor='my-modal-4' className='btn modal-button hidden'>
        open modal
      </label>

      <input ref={openRef} type='checkbox' id='my-modal-4' className='modal-toggle' />
      <label
        htmlFor='my-modal-4'
        className='modal cursor-pointer'
        onClick={() => {
          if (open) {
            setOpen(false);
            setUp(false);
          }
        }}>
        <label className='modal-box p-0 w-max relative' htmlFor=''>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </label>
      </label>
    </div>
  );
};

export default DateModal;
