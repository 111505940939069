import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

const EmailModal = ({ open, cancelBtn, confirmBtn }) => {
  const [up, setUp] = useState(false);
  const openRef = useRef();
  const [code, setCode] = useState('');

  useEffect(() => {
    if (open && up === false) {
      setUp(true);
      openRef.current.click();
    }

    if (open === false && up) {
      setUp(false);
      // close();
      openRef.current.click();
    }

    // }
  }, [open, up]);
  return (
    <div>
      <label htmlFor='my-modal' className='btn modal-button hidden'>
        open modal
      </label>

      <input ref={openRef} type='checkbox' id='my-modal' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box w-11/12 max-w-xl bg-secondary'>
          <label
            onClick={() => {
              cancelBtn();
              // openRef.current.click();
            }}
            className='btn btn-sm btn-error btn-circle absolute right-2 top-2'>
            ✕
          </label>
          <h3 className='font-bold text-lg '>Verify Email</h3>
          <div className='w-full my-4'>
            <div className='form-control w-full max-w-xl'>
              <label className='label'>
                <span className='label-text'>Email Code:</span>
              </label>

              <input
                type='text'
                placeholder='Type here...'
                className='input input-bordered w-full bg-secondary input-primary pl-10'
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          </div>

          <button
            type={'button'}
            onClick={() => {
              if (code.length > 0) {
                confirmBtn(code);
              }
            }}
            className={`btn btn-primary rounded-2xl btn_main_color no-animation  w-full mb-2`}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailModal;
