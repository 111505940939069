import React, { useState, useRef, useEffect } from 'react';
import Upload from '../../../Assets/images/icons/upload.svg';
import Next from '../../../Assets/images/icons/next.svg';
import CloseIcon from '../../../Assets/images/icons/close.svg';

import Back from '../../../Assets/images/icons/mobile/back.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import LoadingFull from '../../../Components/Loading/LoadingFull';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Notify from '../../../Components/Toast';
import DateModal from '../../../Components/date/DateModal';
import { faAdd, faCalendarDay, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
// import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { MultiSelect } from 'react-multi-select-component';
import userApi, { apiUrl } from '../../../Redux/api';
import { setError } from '../../../Redux/Hatch/slice';
import { faCalendarDays, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import LOGODARK from '../../../Assets/images/logo_dark.png';
import LOGOLight from '../../../Assets/images/logo_light.png';
import Skill from '../../../Components/Skill';
import Select, { components } from 'react-select';
import { colors } from '../../../utils/color';

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-');
}

const Option = (props) => (
  <components.Option {...props} isDisabled={props.data.disabled ? props.data.disabled : false}>
    <div className=' text-base-100 flex flex-row items-center w-full'>
      {props.data.icon && <img src={props.data.icon} alt='logo' className='w-6 mr-3' />}

      <span className='text-black'>{props.data.label}</span>
    </div>
  </components.Option>
);

const Create = () => {
  const [loading, setLoading] = useState(false);
  const [roomTitle, setRoomTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [tabs, setTabs] = useState(2);
  const [trxP, setTrxP] = useState(false);
  const [image, setImage] = useState('');
  const [selected, setSelected] = useState([]);
  const [option, setOption] = useState([]);
  const [openSkill, setOpenSkill] = useState(false);
  const [listSkill, setListSkill] = useState([]);
  const [userSkill, setUserSkill] = useState([]);
  const [proposalType, setProposalType] = useState('As Customer (Payor)');
  const [coins, setCoins] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [icons, setIcons] = useState([]);
  const [icon, setIcon] = useState(null);

  const [coinsV, setCoinsV] = useState([]);
  const [selectedCoinV, setSelectedCoinV] = useState(null);

  const [disabledFields, setDisabledFields] = useState({
    create: false,
    step: false,
  });
  const [stepsData, setStepsData] = useState([]);
  const [stepData, setStepData] = useState({
    name: '',
    date: [],
    amount: '',
    isEdit: false,
    index: 0,
  });

  const [modalData, setModalData] = useState({
    type: '',
    index: '0',
    dates: [],
  });

  const [operation, setOperation] = useState(1);
  const [stepMode, setStepMode] = useState(1);
  const selectRef = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [createApi, {}] = userApi.useCreateMutation();
  const [getAll, {}] = userApi.useGetAllHatchMutation();
  const [stepApi, {}] = userApi.useAddMultiStepMutation();
  const [roomApi, {}] = userApi.useCreateRoomMutation();
  const [iconApi, {}] = userApi.useGetIconListMutation();

  const { error, skillsList, info, theme } = useSelector((state) => state.userSlice);
  const { wallet } = useSelector((state) => state.walletSlice);
  const { t } = useTranslation();
  const location = useLocation();

  const schema = z.object({
    name: z.string().min(3, { message: t('hatch.name_error') }),
    description: z.string().min(20, { message: 'Description must be more than 20 character' }),
    time: z.string().min(3, { message: t('hatch.step_date_error') }),
    value: z.string().min(1, { message: t('hatch.step_amount_error') }),
  });

  const changeTab = (num) => {
    setTabs(num);
  };

  useEffect(() => {
    if (info) {
      const list = [];
      let listV = [
        { label: 'Network (Ethereum)', value: 'Network (Ethereum)', isDisabled: true },
        {
          label: 'Stablecoins',
          options: [],
        },
        {
          label: 'Tokens',
          options: [],
        },
      ];
      let selectedTemp;
      let selectedTempV;
      Object.entries(info.Coin).forEach((value, index) => {
        list.push({
          key: value[0],
          value: value[1],
        });

        if (index <= 1) {
          listV[1].options.push({
            value: value[0],
            label: value[0],
            icon: value[1].icon,
            data: value[1],
          });
        } else {
          listV[2].options.push({
            value: value[0],
            label: value[0],
            icon: value[1].icon,
            data: value[1],
          });
        }

        if (value[0] === 'USDT') {
          selectedTemp = {
            key: value[0],
            value: value[1],
          };
          selectedTempV = {
            value: value[0],
            label: value[0],
            icon: value[1].icon,
            data: value[1],
          };
        }
      });

      setCoinsV(listV);
      setSelectedCoinV(selectedTempV);
      setCoins(list);
      setSelectedCoin(selectedTemp);

      const tempFunc = async () => {
        try {
          const response = await iconApi().unwrap();
          let list = [
            {
              label: 'icons',
              options: [],
            },
          ];

          response.data.list.forEach((val, index) => {
            list[0].options.push({
              value: val,
              label: '',
              icon: `${apiUrl}/${val}`,
              data: val,
            });
          });
          setIcons(list);
        } catch (error) {}
      };

      tempFunc();
    }
  }, [info]);

  useEffect(() => {
    if (skillsList && Array.isArray(skillsList)) {
      const temp = [];
      const tempNames = [];
      const selc = [];
      skillsList.map((val) => {
        if (val.title) {
          tempNames.push(val.title.toLowerCase());
          temp.push({ label: val.title, value: val.id });
        }
      });

      setListSkill(tempNames);
      setUserSkill(tempNames);
      setSelected(selc);

      setOption(temp);
    }
  }, [skillsList]);

  useEffect(() => {
    if (error) {
      Notify({ message: error, type: 'error', duration: 3000 });
    }
  }, [error]);

  const {
    register,
    handleSubmit,
    reset,

    getValues,

    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const imageUpload = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setImage(objectUrl);
  };

  useEffect(() => {
    const temp = location.search.replace('?', '');
    if (temp) {
      changeTab(1);
    } else {
      changeTab(2);
    }
  }, [location]);

  useEffect(() => {
    if (modalData.type === '1') {
      const now = new Date().setUTCHours(0, 0, 0, 0);
      const date = getValues().time;

      if (new Date(modalData.dates[0]).getTime() < now) {
        Notify({ message: 'Please add a valid date', type: 'error', duration: 3000 });
        return;
      }

      if (new Date(modalData.dates[0]) > new Date(date)) {
        Notify({ message: 'Step date can not be more than proposal date', type: 'error', duration: 3000 });
        return;
      }

      setStepData({ ...stepData, date: modalData.dates });
    }
  }, [modalData.dates]);

  useEffect(() => {
    if (operation === 2) {
      if (stepMode === 1) {
        setStepsData([stepData]);

        if (stepData.amount.length > 0 && stepData.name.length > 5 && stepData.date.length > 0) {
          setDisabledFields({ ...disabledFields, create: false });
        } else {
          setDisabledFields({ ...disabledFields, create: true });
        }
      }

      if (stepMode === 2) {
        let data = disabledFields;
        if (stepsData.length > 0) {
          data.create = false;
        } else {
          data.create = true;
        }

        if (stepData.amount.length > 0 && stepData.name.length > 5 && stepData.date.length > 0) {
          data.step = false;
        } else {
          data.step = true;
        }
      }
    } else {
      setDisabledFields({ ...disabledFields, create: false });
    }
  }, [stepData, stepMode, operation]);

  const handleFormData = async (data) => {
    const now = new Date().setUTCHours(0, 0, 0, 0);

    if (stepMode === 1) {
      setStepData({
        ...stepData,
        date: [formatDate(new Date(data.time)), formatDate(new Date(data.time))],
        amount: data.value,
      });
    }

    if (new Date(data.time).getTime() < now) {
      Notify({ message: 'Please add a valid date', type: 'error', duration: 3000 });
      return;
    }

    if (Number(data.value) <= 0) {
      Notify({ message: 'Please add a valid value for your proposal', type: 'error', duration: 3000 });
      return;
    }
    if (operation === 1) {
      setOperation(2);

      return;
    }
    if (operation === 2) {
      if (stepMode === 1) {
        if (stepData.amount.length <= 0 || stepData.name.length <= 5 || stepData.date.length <= 1) {
          const er =
            stepData.amount.length <= 0
              ? 'Please add a value for phase'
              : stepData.name.length <= 5
              ? 'Phase Title Must contain 6 or more characters'
              : 'Please add a valid date for phase';
          Notify({ message: er, type: 'error', duration: 3000 });

          return;
        }
      }

      let amount = 0;

      if (stepsData.length <= 0) {
        Notify({ message: 'Please add a step for your proposal', type: 'error', duration: 3000 });
        return;
      }

      stepsData.map((val) => {
        amount += Number(val.amount);
      });

      if (amount !== Number(data.value)) {
        Notify({
          message: 'total of steps amount must be equal to proposal value',
          type: 'error',
          duration: 3000,
        });
        return;
      }
      // create proposal first
      try {
        setLoading(true);
        setTrxP(true);

        let stepPayList = [];

        let emptyList = [];

        let list = [];
        const othersSkill = [];

        selected.map((val) => {
          if (!val.value.includes('d/')) {
            list.push(val.value);
          } else {
            othersSkill.push(val.label);
          }
        });

        stepsData.map((val) => {
          stepPayList.push(val.amount);
          emptyList.push(0);
        });

        if (!selectedCoin) {
          Notify({
            message: 'Please select an network',
            type: 'error',
            duration: 3000,
          });
          return;
        }

        // if (proposalType === 'As Customer (Payor)') {
        //   const web3Approve = await approveProposal(wallet, data.value, selectedCoin.value.contract);

        //   if (web3Approve.success === false) {
        //     const resE = web3Approve.error
        //       ? typeof web3Approve.error === 'string'
        //         ? web3Approve.error
        //         : web3Approve.error.message
        //       : 'Error Transaction';
        //     Notify({ message: resE, type: 'error', duration: 3000 });
        //     setLoading(false);
        //     setTrxP(false);
        //     return;
        //   }
        // }
        const temp = location.search.replace('?', '');

        await createApi({
          base_token: `open_${wallet}_hatch`,
          code: '000000',
          title: data.name,
          description: data.description,
          due_date: new Date(data.time).getTime(),
          budget: data.value,
          other_skills: othersSkill,
          skills: list,
          network: selectedCoin.key,
          can_add: stepMode === 1 ? false : true,
          lazyAuthor: proposalType === 'As Customer (Payor)' ? false : true,
          chat_id: temp ? temp : null,
        }).unwrap();

        const responseGet = await getAll().unwrap();

        const last = responseGet.data[responseGet.data.length - 1];

        let listOfSteps = [];

        stepsData.map(async (step) => {
          listOfSteps.push({
            title: step.name,
            slot_budget: step.amount,
            due_date: new Date(step.date[1]).getTime(),
            start_date: new Date(step.date[0]).getTime(),
          });
        });

        // create
        await stepApi({
          base_token: `open_${wallet}_hatch`,
          code: '000000',
          id: last.id,
          timelines: listOfSteps,
        }).unwrap();

        reset();
        setLoading(false);
        setTrxP(false);
        navigate(`/panel/hatch/info?${last.id}`, { state: 'new' });
      } catch (error) {
        // console.log(error);
        setLoading(false);
        setTrxP(false);
        const temp = error.data.error ? error.data.error[0].message : error.message;

        dispatch(setError({ error: temp }));

        // navigate('/',);
      }
      // add steps
    }
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props} className='flex  items-center '>
      <img src={selectedCoinV.icon} alt='s-logo' className='w-6 mr-3' />
      <span className='text-neutral'> {children}</span>
    </components.SingleValue>
  );

  const SingleValueT = ({ children, ...props }) => (
    <components.SingleValue {...props} className='flex  items-center '>
      <img src={`${apiUrl}/${icon.value}`} alt='s-logo' className='w-6 mr-3' />
      <span className='text-neutral'> {children}</span>
    </components.SingleValue>
  );

  const createRoom = async () => {
    try {
      if (roomTitle.length <= 5) {
        Notify({ message: 'Title must be more than 5 character', type: 'error', duration: 3000 });
        return;
      }

      if (roomTitle.length > 50) {
        Notify({ message: 'Title must be let than 50 character', type: 'error', duration: 3000 });
        return;
      }

      setLoading(true);

      let item = [...Object.values(colors)][Math.floor(Math.random() * Object.values(colors).length)];

      const response = await roomApi({ title: roomTitle, color: item }).unwrap();

      // setTimeout(() => {
      setLoading(false);
      navigate(`/panel/chat/?${response.data.message}`);
      // }, 2000);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setTrxP(false);
      const temp = error.data.error ? error.data.error[0].message : error.message;

      dispatch(setError({ error: temp }));
    }
  };

  return (
    <div className='w-full h-full py-6 md:py-16 px-4 md:px-8 text-neutral'>
      <DateModal open={open} setOpen={setOpen} setModalData={setModalData} modalData={modalData} />
      <Skill
        open={openSkill}
        cancelBtn={() => setOpenSkill(false)}
        addBtN={(data) => {
          const val = userSkill;
          val.push(data);
          setUserSkill(val);

          // add to all
          const all = listSkill;
          all.push(data.toLowerCase());
          setListSkill(all);

          // add to options
          const opt = option;

          opt.unshift({ label: data, value: `d/${val.length}` });
          setOption(opt);

          // add to selected
          const slec = selected;
          slec.push({ label: data, value: `d/${val.length}` });
          setSelected(slec);

          // close
          setOpenSkill(false);
        }}
        list={listSkill}
      />
      {loading ? (
        <LoadingFull trx={trxP} />
      ) : (
        <div className='card  bg-transparent text-neutral'>
          {info && (
            <div className='card-body'>
              <div className='flex items-center mb-8'>
                <Link to='/panel/hatch' className='lg:hidden'>
                  <img src={Back} alt='back' />
                </Link>
                {/* {tabs === 1 && (
                  <h2 className='card-title pl-4 lg:pl-0'>
                    {' '}
                    {tabs === 1 ? t('hatch.create') : 'Create Room'}
                  </h2>
                )} */}
              </div>

              {/* <input onChange={imageUpload} ref={imgRef} type='file' className='hidden' /> */}
              {/* <div className='avatar border-2 shadow-lg shadow-info border-info rounded-3xl   w-max mx-auto md:mx-0 mb-8'> */}
              {/* {tabs === 1 && (
                <div className='w-20 '>
                  <img src={theme === '2' ? LOGODARK : LOGOLight} alt='logo' />
                </div>
              )} */}
              {/* </div> */}

              {/* <div className='tabs  mt-2'>
                <a
                  onClick={() => changeTab(1)}
                  className={`tab tab-bordered text-sm md:text-lg  px-2 md:px-4 ${
                    tabs === 1 && 'text-neutral border-info'
                  }`}>
                  Normal way
                </a>
                <a
                  onClick={() => changeTab(2)}
                  className={`tab tab-bordered text-sm md:text-lg px-2 md:px-4 ${
                    tabs === 2 && 'text-neutral border-info'
                  }`}>
                  Create Room
                </a>
              </div> */}

              {tabs === 1 ? (
                <form className='py-7' onSubmit={handleSubmit((d) => handleFormData(d))}>
                  <div className='grid grid-cols-1 gap-4 text-neutral'>
                    <div className='form-control w-full  lg:w-1/3  '>
                      <label className='label'>
                        <span className='label-text'>Define your role :</span>
                      </label>

                      <select
                        value={proposalType}
                        // disabled={operation === 1 ? false : true}
                        onChange={(e) => {
                          setProposalType(e.target.value);
                        }}
                        className='select bg-secondary select-bordered border-neutral disabled:bg-secondary '>
                        <option value={'As Customer (Payor)'}>Customer (Payor)</option>
                        <option value={'As Service provider (Payee)'}>Service provider (Payee)</option>
                      </select>
                    </div>
                    <div className='w-full flex flex-col lg:flex-row justify-between '>
                      <div className='form-control w-full lg:w-2/5  lg:mr-2'>
                        <label className='label'>
                          <span className='label-text'>{t('hatch.name')}</span>
                        </label>

                        <input
                          type='text'
                          placeholder='Type here...'
                          className='input input-bordered w-full bg-secondary border-neutral disabled:bg-secondary'
                          {...register('name')}
                          disabled={operation === 1 ? false : true}
                        />
                        <label className='label'>
                          {errors.name?.message && (
                            <span className='label-text-alt text-error'>{errors.name?.message}</span>
                          )}
                        </label>
                      </div>
                      <div className='form-control w-full lg:w-1/5  lg:mr-2'>
                        <label className='label'>
                          <span className='label-text'>{t('hatch.time')}:</span>
                        </label>

                        <div className='relative'>
                          <input
                            type={'date'}
                            placeholder='Type here...'
                            className='input input-bordered w-full bg-secondary border-neutral disabled:bg-secondary'
                            {...register('time')}
                            disabled={operation === 1 ? false : true}
                          />
                          {/* <FontAwesomeIcon
                        icon={faCalendarDays}
                        size='lg'
                        className=' text-neutral  absolute right-4 z-10 top-4'
                      /> */}
                        </div>
                        <label className='label'>
                          {errors.time?.message && (
                            <span className='label-text-alt text-error'>{errors.time?.message}</span>
                          )}
                        </label>
                      </div>
                      <div className='form-control w-full lg:w-2/5 '>
                        <label className='label'>
                          <span className='label-text'>{t('hatch.value')}:</span>
                        </label>

                        <div className='flex items-center '>
                          <input
                            type={'number'}
                            placeholder='Type here...'
                            step={'0.1'}
                            className='input input-bordered w-1/2 bg-secondary border-neutral rounded-tr-none rounded-br-none disabled:bg-secondary'
                            {...register('value')}
                            disabled={operation === 1 ? false : true}
                          />

                          {/* <div className='relative'> */}

                          {coins.length && (
                            // <select
                            //   ref={selectRef}
                            //   value={selectedCoin?.key}
                            //   disabled={operation === 1 ? false : true}
                            //   onChange={(e) => {
                            //     const find = coins.find((val) => val.key === e.target.value);

                            //     if (find) {
                            //       setSelectedCoin(find);
                            //     }
                            //   }}
                            //   className='select bg-secondary select-bordered select-info disabled:bg-secondary '>
                            //   <option disabled selected>
                            //     Network (Ethereum)
                            //   </option>

                            //   <optgroup label='Stablecoins'>
                            //     {coins.length > 0 &&
                            //       coins.slice(0, 2).map((val) => {
                            //         return (
                            //           <option
                            //             className='flex items-center justify-between'
                            //             value={val.key}
                            //             dataImg={val.value.icon}
                            //             key={val.key}>
                            //             <span> {val.key}</span>
                            //           </option>
                            //         );
                            //       })}
                            //   </optgroup>

                            //   <optgroup label='Tokens'>
                            //     {coins.length > 0 &&
                            //       coins.slice(2).map((val) => {
                            //         return (
                            //           <option
                            //             className='flex items-center justify-between w-full'
                            //             value={val.key}
                            //             key={val.key}>
                            //             <span> {val.key}</span>
                            //           </option>
                            //         );
                            //       })}
                            //   </optgroup>
                            // </select>

                            <Select
                              className='w-1/2 h-full border-neutral rounded-tr-xl rounded-br-xl'
                              options={coinsV}
                              value={selectedCoinV}
                              onChange={(e) => {
                                setSelectedCoinV(e);
                                const find = coins.find((val) => val.key === e.value);

                                if (find) {
                                  setSelectedCoin(find);
                                }
                              }}
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  backgroundColor: theme && theme === '1' ? '#252525' : '#D3D3D3',
                                  borderColor: theme && theme === '1' ? '#fff' : '#000',
                                  height: '100%',
                                }),

                                singleValue: (base) => ({
                                  ...base,
                                  display: 'flex',
                                  alignItems: 'center',
                                  backgroundColor: 'transparent',
                                }),
                              }}
                              components={{
                                Option,
                                SingleValue,
                              }}
                            />
                          )}

                          {/* </div> */}
                        </div>
                        {/* {selectedCoin && (
                        <img
                          alt='coin'
                          src={selectedCoin.value.icon}
                          className=' text-neutral absolute right-8 top-12 w-6'
                        />
                      )} */}
                        {/* <FontAwesomeIcon
                        icon={faEthereum}
                        size='xl'
                        className=' text-neutral absolute right-8 top-12'
                      /> */}
                        <label className='label'>
                          {errors.value?.message && (
                            <span className='label-text-alt text-error'>{errors.value?.message}</span>
                          )}
                        </label>
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='form-control w-full max-w-xl'>
                        <label className='label'>
                          <span className='label-text'>Skills:</span>
                        </label>
                        <div className='flex w-full justify-between items-center'>
                          <MultiSelect
                            options={option}
                            value={selected}
                            onChange={setSelected}
                            labelledBy='Select'
                            className='bg-secondary border border-neutral rounded-lg w-full mr-2'
                          />
                          <button
                            onClick={() => {
                              setOpenSkill(true);
                            }}
                            className='btn btn-ghost btn-circle border-neutral btn-outline w-12 hover:bg-transparent '>
                            <FontAwesomeIcon
                              icon={faAdd}
                              size='xl'
                              className='mx-auto text-info   rounded-2xl'
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className='w-full'>
                      <div className='form-control w-full max-w-xl'>
                        <label className='label'>
                          <span className='label-text'>{t('hatch.desc')}:</span>
                        </label>
                        <textarea
                          className='textarea border-neutral bg-secondary w-full disabled:bg-secondary'
                          placeholder='description'
                          disabled={operation === 1 ? false : true}
                          {...register('description')}></textarea>
                        <label className='label'>
                          {errors.description?.message && (
                            <span className='label-text-alt text-error'>{errors.description?.message}</span>
                          )}
                        </label>
                      </div>
                    </div>

                    {operation === 2 && (
                      <>
                        <div className='divider'></div>
                        <div className='w-max'>
                          <div className='form-control'>
                            <label className='label cursor-pointer'>
                              <input
                                type='radio'
                                name='radio-6'
                                checked={stepMode === 1 ? true : false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setStepMode(1);

                                    setStepData({
                                      name: '',
                                      date: [
                                        formatDate(new Date(getValues().time)),
                                        formatDate(new Date(getValues().time)),
                                      ],

                                      isEdit: false,
                                      index: 0,
                                      amount: getValues().value,
                                    });
                                    setStepsData([]);
                                  }
                                }}
                                className='radio border-neutral checked:bg-green-500'
                              />
                              <span className='label-text ml-4'>One-time full payment</span>
                            </label>
                          </div>
                          <div className='form-control'>
                            <label className='label cursor-pointer'>
                              <input
                                type='radio'
                                name='radio-6'
                                checked={stepMode === 2 ? true : false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setStepMode(2);
                                    setStepData({
                                      name: '',
                                      date: [],

                                      isEdit: false,
                                      index: 0,
                                      amount: '',
                                    });
                                    setStepsData([]);
                                    setStepsData([]);
                                  }
                                }}
                                className='radio border-neutral checked:bg-green-500'
                              />
                              <span className='label-text ml-4'>Milestone</span>
                            </label>
                          </div>
                        </div>

                        <div className='w-full'>
                          <div className='form-control w-full '>
                            <label className='label'>
                              <span className='label-text'>
                                {stepMode === 1 ? 'One-time full payment' : t('hatch.steps')}
                              </span>
                            </label>
                          </div>
                        </div>

                        {stepMode === 2 &&
                          stepsData.length > 0 &&
                          stepsData.map(({ name, date, amount }, index) => {
                            // console.log({ id, name, date, amount, currency });

                            return (
                              <div key={index}>
                                <div className='w-full'>
                                  <div className='form-control w-full '>
                                    <div className='w-full flex flex-col md:flex-row justify-between items-center'>
                                      <div className='relative mb-2 md:mb-0 w-full md:mr-2 '>
                                        <div className='flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none'>
                                          <span className='text-success'>Step {index + 1} :</span>
                                        </div>
                                        <input
                                          type='text'
                                          placeholder='Type here...'
                                          className='input input-bordered w-full  bg-secondary border-neutral pl-20 disabled:bg-secondary'
                                          value={name}
                                          disabled
                                          onChange={() => {}}
                                        />
                                      </div>

                                      <div className='form-control mb-2 md:mb-0 md:mr-2 w-full '>
                                        <input
                                          // onClick={() => {
                                          //   setOpen(false);
                                          //   setModalData({ dates: [], type: '2'  , index});
                                          // }}
                                          type='text'
                                          placeholder='From'
                                          className='input input-bordered w-full bg-secondary border-neutral disabled:bg-secondary'
                                          value={date.length > 0 ? `${date[0]} to ${date[1]}` : ''}
                                          disabled
                                          onChange={() => {}}
                                        />
                                      </div>
                                      <div className='form-control mb-2 md:mb-0'>
                                        <div className='relative'>
                                          <div className='flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none'>
                                            {selectedCoin && (
                                              <img
                                                alt='coin'
                                                src={selectedCoin.value.icon}
                                                className=' text-neutral  w-6'
                                              />
                                            )}
                                          </div>
                                          <input
                                            type='text'
                                            placeholder='Type here...'
                                            value={amount}
                                            className='input input-bordered  bg-secondary border-neutral disabled:bg-secondary '
                                            // onChange={(e) => {
                                            //   amount = e.target.value;
                                            // }}
                                            disabled
                                            onChange={() => {}}
                                            // {...register('name')}
                                          />
                                        </div>
                                      </div>
                                      <button
                                        type={'button'}
                                        onClick={() => {
                                          setStepData({
                                            name,
                                            amount,
                                            date,
                                            index,
                                            isEdit: true,
                                          });
                                        }}
                                        className={`btn btn-warning rounded-2xl  no-animation btn-outline `}>
                                        <FontAwesomeIcon
                                          icon={faPenToSquare}
                                          size='xl'
                                          className=' text-neutral '
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        <div className='w-full'>
                          <div className='form-control w-full '>
                            <div className='w-full flex flex-col lg:flex-row justify-between '>
                              <div className='form-control mb-2 md:mb-0 w-w-full lg:w-1/3 '>
                                <input
                                  type='text'
                                  placeholder='Input payment description'
                                  className='input input-bordered  bg-secondary w-full border-neutral md:mr-2 mb-2 md:mb-0 '
                                  value={stepData.name}
                                  onChange={(e) => setStepData({ ...stepData, name: e.target.value })}
                                />
                                <label className='label'>
                                  {stepData.name.length <= 5 && (
                                    <span className='label-text-alt text-error'>
                                      Must contain 6 or more characters
                                    </span>
                                  )}
                                </label>
                              </div>

                              {stepMode === 2 && (
                                <div className='form-control mb-2 lg:mb-0 lg:mr-2 w-full lg:w-1/3 '>
                                  <div className='input-group'>
                                    <input
                                      onClick={() => {
                                        setOpen(true);
                                        setModalData({ dates: [], type: '1' });
                                      }}
                                      type='text'
                                      placeholder='From'
                                      className='input input-bordered w-1/2 bg-secondary border-neutral '
                                      value={stepData.date.length > 0 ? `${stepData.date[0]}` : ''}
                                      onChange={() => {}}
                                    />
                                    <input
                                      onClick={() => {
                                        setOpen(true);
                                        setModalData({ dates: [], type: '1' });
                                      }}
                                      type='text'
                                      placeholder='To'
                                      className='input input-bordered w-1/2 bg-secondary border-neutral '
                                      value={stepData.date.length > 0 ? `${stepData.date[1]}` : ''}
                                      onChange={() => {}}
                                    />
                                  </div>
                                </div>
                              )}
                              {stepMode === 1 && (
                                <div className='form-control mb-2 lg:mb-0 lg:mr-2 w-full lg:w-1/3 '>
                                  <div className='input-group'>
                                    <input
                                      type='text'
                                      placeholder='To'
                                      disabled
                                      className='input input-bordered w-full bg-secondary border-neutral  disabled:bg-secondary'
                                      defaultValue={stepData.date.length > 0 ? `${stepData.date[1]}` : ''}
                                      onChange={() => {}}
                                    />
                                  </div>
                                </div>
                              )}

                              <div className='form-control mb-2 lg:mb-0'>
                                <div className='relative'>
                                  <div className='flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none'>
                                    {/* <FontAwesomeIcon icon={faEthereum} size='xl' className=' text-neutral ' /> */}
                                    {selectedCoin && (
                                      <img
                                        alt='coin'
                                        src={selectedCoin.value.icon}
                                        className=' text-neutral  w-6'
                                      />
                                    )}
                                  </div>
                                  <input
                                    type={'number'}
                                    placeholder='Type here...'
                                    value={stepData.amount}
                                    className='input input-bordered w-full  bg-secondary border-neutral disabled:bg-secondary '
                                    step='0.1'
                                    disabled={stepMode === 1 ? true : false}
                                    onChange={(e) => {
                                      // if (Number(e.target.value) <= 0) {
                                      //   Notify({
                                      //     message: 'Please add a valid value for your step',
                                      //     type: 'error',
                                      //     duration: 3000,
                                      //   });
                                      //   return;
                                      // }
                                      setStepData({ ...stepData, amount: e.target.value });
                                    }}
                                    // {...register('name')}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {stepMode === 2 && (
                          <div className='w-full'>
                            <div className='form-control w-full '>
                              <button
                                disabled={disabledFields.step}
                                onClick={() => {
                                  if (
                                    stepData.amount.length > 0 &&
                                    stepData.name.length > 5 &&
                                    stepData.date.length > 0
                                  ) {
                                    if (stepData.isEdit) {
                                      const temp = stepsData;
                                      temp[stepData.index] = stepData;
                                      setStepsData(temp);
                                    } else {
                                      if (stepsData.length > 0 && stepMode === 1) {
                                        Notify({
                                          message: 'Sorry You can not add more than 1 step in this mode',
                                          type: 'error',
                                          duration: 3000,
                                        });
                                        return;
                                      }
                                      const temp = stepsData;
                                      temp.push(stepData);
                                      setStepsData(temp);
                                    }

                                    setStepData({
                                      name: '',
                                      date: [],
                                      amount: '',
                                      isEdit: false,
                                      index: 0,
                                    });
                                  } else {
                                    const er =
                                      stepData.amount.length <= 0
                                        ? 'Please add a value for phase'
                                        : stepData.name.length <= 5
                                        ? 'Phase Title Must contain 6 or more characters'
                                        : 'Please add a valid date for phase';
                                    Notify({ message: er, type: 'error', duration: 3000 });
                                  }
                                }}
                                type={'button'}
                                className={`btn btn-outline  rounded-2xl no-animation  w-full md:w-48 disabled:bg-secondary`}>
                                <span>{stepData.isEdit ? 'Edit Step' : t('hatch.step_new')}</span>
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className='card-actions justify-start mt-12'>
                      {operation === 2 && (
                        <button
                          type={'button'}
                          onClick={() => setOperation(1)}
                          className={`btn  rounded-2xl  no-animation btn-outline w-full md:w-48`}>
                          <span>Edit</span>
                          <FontAwesomeIcon icon={faPenToSquare} size='xl' className=' text-info ml-2' />
                        </button>
                      )}
                      <button
                        disabled={disabledFields.create}
                        className={`btn  rounded-2xl  no-animation  w-full md:w-48 disabled:bg-secondary`}>
                        <span>{operation === 2 ? t('hatch.next') : 'Next'}</span>
                        <img src={Next} alt='' className='mt-1 ml-1 ' />
                      </button>
                    </div>
                  </div>
                </form>
              ) : null}

              {tabs === 2 ? (
                <div className='w-full h-full'>
                  {/* <div className='mb-3 form-control w-full'>
                    <label className='label'>
                      <span className='label-text'>Select Icon:</span>
                    </label>

                    <Select
                      className='w-max h-full border-neutral rounded-tr-xl rounded-br-xl'
                      options={icons}
                      value={icon}
                      onChange={(e) => {
                        console.log(e);
                        setIcon(e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: theme && theme === '1' ? '#252525' : '#D3D3D3',
                          borderColor: theme && theme === '1' ? '#fff' : '#000',
                          height: '100%',
                        }),

                        singleValue: (base) => ({
                          ...base,
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: 'transparent',
                        }),
                      }}
                      components={{
                        Option,
                        SingleValue: SingleValueT,
                      }}
                    />
                  </div> */}
                  <div className='form-control w-full   lg:mr-2'>
                    <label className='label'>
                      <span className='label-text'>Give your Hatch a name:</span>
                    </label>

                    <input
                      type='text'
                      placeholder='Type here...'
                      className='input input-bordered w-full bg-secondary border-neutral disabled:bg-secondary'
                      value={roomTitle}
                      onChange={(e) => setRoomTitle(e.target.value)}
                    />
                  </div>
                  <button
                    type={'button'}
                    onClick={() => createRoom()}
                    className={`btn  rounded-2xl  no-animation btn-outline w-full md:w-48 mt-8`}>
                    <span>Create</span>
                    <FontAwesomeIcon icon={faAdd} size='xl' className=' text-info ml-2' />
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Create;
