import { io } from 'socket.io-client';
import { apiUrl } from '../Redux/api';

export let socket;

export const addSocket = (wallet) => {
  if (!socket) {
    // console.log('call socket');
    socket = io(apiUrl, {
      query: {
        token: wallet,
      },
      reconnection: true,
      reconnectionDelay: 4000,
      transports: ['websocket'],
    });
  }
};
