import { disconnectWalletConnect, getWalletConnect } from '../../../config/walletConnect';
import store from '../../../Redux/store';
import { disconnect } from '../../../Redux/Wallet/slice';
import { logout } from '../../../Redux/User/slice';
import { addProvider, getChain } from '../../../utils/web3.methods';
import Notify from '../../../Components/Toast';
let chains = [1];

export const checkWallet = async (type) => {
  if (type.length <= 0) {
    const data = localStorage.getItem('wallet');
    if (data) {
      const parseData = await JSON.parse(data);

      if (parseData.type) {
        switch (parseData.type) {
          case '1':
            type = 'metamask';
            break;
          case '2':
            type = 'walletConnect';
            break;

          default:
            // window.localStorage.removeItem('wallet');
            break;
        }
      }
    }
  }

  // console.log({ type });
  switch (type) {
    case 'metamask':
      addProvider(window.ethereum);
      window.ethereum.on('chainChanged', async () => {
        // console.log('chainChanged meta');
        store.dispatch(disconnect());
        store.dispatch(logout());
        return;
      });
      window.ethereum.on('accountsChanged', (accounts) => {
        // console.log('accountsChanged meta');
        store.dispatch(disconnect());
        store.dispatch(logout());
        return;
      });

      window.ethereum.on('connect', async (info) => {
        // console.log('connect meta');
        const chain = await getChain();

        if (!chains.includes(chain)) {
          Notify({ message: `Please select one of these chains ${chains}`, type: 'error', duration: 3000 });
          store.dispatch(disconnect());
          store.dispatch(logout());
        }
      });
      window.ethereum.on('disconnect', (error) => {
        // console.log('disconnect meta');
        store.dispatch(disconnect());
        store.dispatch(logout());
        return;
      });
      break;

    case 'walletConnect':
      const provider = getWalletConnect();
      // console.log('................ here ....................');
      // console.log({ provider });
      if (provider) {
        addProvider(provider);
        // console.log({ ddd: provider.chainId });
        if (provider.chainId.toString() !== chains[0].toString()) {
          Notify({ message: 'Please connect to sepolia chain', type: 'error', duration: 3000 });
          store.dispatch(disconnect());
          store.dispatch(logout());
          disconnectWalletConnect();
          return;
        }
        provider.on('chainChanged', async () => {
          // console.log('chainChanged walletConnect');
          store.dispatch(disconnect());
          store.dispatch(logout());
          disconnectWalletConnect();
          return;
        });
        provider.on('connect', async () => {
          // console.log('connect walletConnect');
          const chain = await getChain();
          // console.log({ chain });
        });
        provider.on('accountsChanged', (accounts) => {
          // console.log('accountsChanged walletConnect');
          store.dispatch(disconnect());
          store.dispatch(logout());
          disconnectWalletConnect();
          return;
        });
        provider.on('disconnect', (error) => {
          // console.log('disconnect walletConnect');
          store.dispatch(disconnect());
          store.dispatch(logout());
          disconnectWalletConnect();
          return;
        });
      }

      break;

    default:
      break;
  }
};
