import React, { useEffect, useRef, useState } from 'react';
import userApi from '../../Redux/api';
import Notify from '../Toast';

const Skill = ({ open, cancelBtn, addBtN, list }) => {
  const [up, setUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [skill, setSkill] = useState('');
  const openRef = useRef();

  useEffect(() => {
    if (open && up === false) {
      setUp(true);
      openRef.current.click();
    }

    if (open === false && up) {
      setUp(false);

      openRef.current.click();
    }
  }, [open, up]);
  return (
    <div>
      <label htmlFor='my-modal' className='btn modal-button hidden'>
        open modal
      </label>

      <input ref={openRef} type='checkbox' id='my-modal' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box w-11/12 max-w-md bg-secondary'>
          <label
            onClick={() => {
              if (loading === false) {
                cancelBtn();
              }
            }}
            className='btn btn-sm btn-error btn-circle absolute right-2 top-2'>
            ✕
          </label>
          <h3 className='font-bold text-lg'>Add Skill</h3>
          <div className='grid grid-cols-1 gap-2'>
            <div className='w-full'>
              <div className='form-control w-full '>
                <label className='label'>
                  <span className='label-text'>Title:</span>
                </label>

                <input
                  type='text'
                  placeholder='Type here'
                  className='input input-bordered w-full bg-secondary input-info pl-10'
                  value={skill}
                  onChange={(e) => {
                    setSkill(e.target.value);
                  }}
                />
              </div>
            </div>

            <button
              disabled={loading}
              onClick={() => {
                if (skill.length > 5) {
                  if (list.includes(skill.toLowerCase())) {
                    Notify({ duration: 3000, message: 'Skill is already in list', type: 'error' });
                  } else {
                    setSkill('');
                    addBtN(skill);
                  }
                } else {
                  Notify({
                    duration: 3000,
                    message: 'Skill Must contain 6 or more characters',
                    type: 'error',
                  });
                }
              }}
              className={`btn btn-success w-full md:w-80 mx-auto mt-6 ${loading && 'loading'}`}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skill;
