import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  selected: null,
  loading: false,
  error: null,
};

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getAll: (state, actions) => {
      const { data } = actions.payload;

      state.list = data;
      state.loading = false;
      state.login = true;
      state.error = null;
    },

    getOne: (state, actions) => {
      const data = actions.payload;

      state.selected = data;
      state.loading = false;
      state.login = true;
      state.error = null;
    },
    setError: (state, actions) => {
      const { error } = actions.payload;
      // console.log(actions.payload);
      state.loading = false;
      state.login = false;
      state.error = error;
    },
    setLoading: (state) => {
      state.loading = true;
    },
  },
});

export const { getAll, getOne, setError, setLoading } = ChatSlice.actions;

export default ChatSlice.reducer;
