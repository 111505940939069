import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Avt from '../../Assets/images/user.png';
import userApi, { apiUrl } from '../../Redux/api';

const AvatarModal = ({ open, cancelBtn, addAvatar, avatar }) => {
  const [up, setUp] = useState(false);
  const openRef = useRef();
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();
  const [avaApi, {}] = userApi.useGetAvatarsMutation();

  useEffect(() => {
    avaApi()
      .unwrap()
      .then((res) => {
        if (res.data.list) {
          setItems(res.data.list);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // console.log(openRef.current);
    // if (openRef.current) {

    if (open && up === false) {
      setUp(true);
      openRef.current.click();
    }

    if (open === false && up) {
      setUp(false);
      // close();
      openRef.current.click();
    }

    // }
  }, [open, up]);
  return (
    <div>
      <label htmlFor='my-modal' className='btn modal-button hidden'>
        open modal
      </label>

      <input ref={openRef} type='checkbox' id='my-modal' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box w-11/12 max-w-xl bg-secondary '>
          <label
            onClick={() => {
              cancelBtn();
              // openRef.current.click();
            }}
            className='btn btn-sm btn-error btn-circle absolute right-2 top-2'>
            ✕
          </label>
          <h3 className='font-bold text-lg mb-4'>Select Avatar</h3>
          <div className=' flex justify-start items-center flex-wrap h-96 overflow-y-auto'>
            {items.map((val) => {
              return (
                <div
                  key={val}
                  onClick={() => {
                    addAvatar(val);
                  }}
                  className={`avatar ${avatar && avatar === val ? 'online' : 'offline'} cursor-pointer m-2`}>
                  <div className='w-24 rounded-full'>
                    <img src={`${apiUrl}/${val}`} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarModal;
