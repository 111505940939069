import React, { useRef, useState } from 'react';
import { useEffect } from 'react';

const GoogleCode = ({ open, close, next }) => {
  const [code, setCode] = useState('');
  const [up, setUp] = useState(false);
  const openRef = useRef();

  useEffect(() => {
    // console.log(openRef.current);
    // if (openRef.current) {

    if (open && up === false) {
      setUp(true);
      openRef.current.click();
    }

    // if (open && up) {
    //   setUp(false);
    //   openRef.current.click();
    // }

    if (open === false && up) {
      setUp(false);
      // close();
      openRef.current.click();
    }

    // }
  }, [open]);

  // useEffect(() => {
  //   return () => {
  //     setUp(false);
  //   };
  // }, []);
  return (
    <div>
      <label htmlFor='googl_modal' className='btn modal-button hidden'>
        open modal
      </label>

      <input ref={openRef} type='checkbox' id='googl_modal' className='modal-toggle' />
      <div className='modal'>
        <div className='modal-box'>
          <label
            onClick={() => {
              close();
              // openRef.current.click();
            }}
            className='btn btn-sm btn-error btn-circle absolute right-2 top-2'>
            ✕
          </label>

          <div className='w-full my-4'>
            <div className='form-control w-full max-w-xl'>
              <label className='label'>
                <span className='label-text'>Please enter your 2-way authenticator code:</span>
              </label>

              <input
                type='text'
                placeholder='Type here...'
                className='input input-bordered w-full bg-secondary input-info pl-10'
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          </div>

          <button
            type={'button'}
            onClick={() => {
              if (code.length > 0) {
                next(code);
                // openRef.current.click();
              }
            }}
            className={`btn btn-primary rounded-2xl btn_main_color no-animation  w-full mb-2`}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleCode;
