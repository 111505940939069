import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { decrypt, encrypt } from '../utils/encryption';
import { logout } from './User/slice';

export let apiUrl = 'https://openhatch.messier.app';
// export let apiUrl = 'https://beta-openhatch.messier.app';
// export let apiUrl = 'http://127.0.0.1:8273';
const baseQ = fetchBaseQuery({
  baseUrl: apiUrl,

  prepareHeaders: (headers, {}) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = window.localStorage.getItem('auth_login');
    const token2 = window.localStorage.getItem('auth_login_');
    if (token) {
      headers.set('accessToken', token);
      headers.set('refreshToken', token2);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQ(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // // try to get a new token
    // const refreshResult = await baseQuery('/refreshToken', api, extraOptions)
    // if (refreshResult.data) {
    //   // store the new token
    //   api.dispatch(tokenReceived(refreshResult.data))
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions)
    // } else {
    api.dispatch(logout());
    // }
  }

  if (result.error) {
    result.error.data = await decrypt(result.error.data.data);
  }

  if (result.data) {
    result.data = await decrypt(result.data.data);
  }

  return result;
};

export const userApi = createApi({
  reducerPath: 'userApi',

  baseQuery: baseQueryWithReauth,

  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/exist',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getUser: builder.mutation({
      query: (credentials) => ({
        url: 'auth/entryUser',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),

    getMe: builder.mutation({
      query: () => ({
        url: 'user/me',
        method: 'Get',
      }),
    }),

    saveProfile: builder.mutation({
      query: (credentials) => ({
        url: 'user/edit',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),

    updateData: builder.mutation({
      query: (credentials) => ({
        url: 'user/edit',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),

    removeUser: builder.mutation({
      query: () => ({
        url: 'user/deActive',
        method: 'Post',
        body: {},
      }),
    }),

    create: builder.mutation({
      query: (credentials) => ({
        url: 'user/create',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getAllHatch: builder.mutation({
      query: () => ({
        url: 'user/userProposals',
        method: 'Get',
      }),
    }),
    getAllDispute: builder.mutation({
      query: () => ({
        url: 'user/getActiveComplaints',
        method: 'Get',
      }),
    }),
    getOneDispute: builder.mutation({
      query: (credentials) => ({
        url: 'user/getActiveComplaint',
        method: 'Get',
        params: {
          id: credentials,
        },
      }),
    }),
    getAllSkills: builder.mutation({
      query: () => ({
        url: 'user/getSkills',
        method: 'Get',
      }),
    }),
    getOneHatch: builder.mutation({
      query: (credentials) => ({
        url: 'user/userProposal',
        method: 'Get',
        params: {
          id: credentials,
        },
      }),
    }),

    updateOne: builder.mutation({
      query: (credentials) => ({
        url: 'user/updateProposal',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    addStep: builder.mutation({
      query: (credentials) => ({
        url: 'user/addTimeline',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    editStep: builder.mutation({
      query: (credentials) => ({
        url: 'user/updateTimeline',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    addPay: builder.mutation({
      query: (credentials) => ({
        url: 'user/addPayTimeline',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    signAuthor: builder.mutation({
      query: (credentials) => ({
        url: 'user/signProposalAuthor',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    signAccepter: builder.mutation({
      query: (credentials) => ({
        url: 'user/signProposalAcceptor',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    acceptProposal: builder.mutation({
      query: (credentials) => ({
        url: 'user/accepterProposal',
        method: 'Get',
        params: {
          id: credentials,
        },
      }),
    }),
    complain: builder.mutation({
      query: (credentials) => ({
        url: 'user/proposalComplaintActive',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    deActiveComplain: builder.mutation({
      query: (credentials) => ({
        url: 'user/proposalComplaintDeActive',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    becomeJudge: builder.mutation({
      query: (credentials) => ({
        url: 'user/requestToJudge',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    editJudge: builder.mutation({
      query: (credentials) => ({
        url: 'user/editToJudge',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    depositJudge: builder.mutation({
      query: (credentials) => ({
        url: 'user/depositAsJudge',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    sendVote: builder.mutation({
      query: (credentials) => ({
        url: 'user/proposalComplaintVote',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    overProposal: builder.mutation({
      query: (credentials) => ({
        url: 'user/OverProposal',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    sendMessage: builder.mutation({
      query: (credentials) => ({
        url: 'user/addMessage',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getAllUsers: builder.mutation({
      query: () => ({
        url: 'user/all?page=1&limit=100000000000000000000000000',
        method: 'Get',
      }),
    }),
    acceptJudge: builder.mutation({
      query: (credentials) => ({
        url: 'user/approveJudge',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    protected: builder.mutation({
      query: () => 'protected',
    }),

    // -------------- new

    getComplaintDetails: builder.mutation({
      query: (credentials) => ({
        url: `user/complaint_detail?id=${credentials}`,
        method: 'Get',
      }),
    }),
    getProposalDetails: builder.mutation({
      query: (credentials) => ({
        url: `user/proposal_detail?id=${credentials}`,
        method: 'Get',
      }),
    }),
    enableAuth: builder.mutation({
      query: (credentials) => ({
        url: 'user/twoAuth',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    addMultiStep: builder.mutation({
      query: (credentials) => ({
        url: 'user/addTimelines',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    payMultiStep: builder.mutation({
      query: (credentials) => ({
        url: 'user/addPayTimelines',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    sendFeedBack: builder.mutation({
      query: (credentials) => ({
        url: 'user/createFeedback',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getFeedback: builder.mutation({
      query: () => ({
        url: 'user/getFeedback',
        method: 'Get',
      }),
    }),
    addOtherSkills: builder.mutation({
      query: (credentials) => ({
        url: 'user/add_other_skill',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getAllNotification: builder.mutation({
      query: (credentials) => ({
        url: `user/getNotifications?id=${credentials}`,
        method: 'Get',
      }),
    }),
    seenChat: builder.mutation({
      query: (credentials) => ({
        url: 'user/seenNotification',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    seenAll: builder.mutation({
      query: (credentials) => ({
        url: 'user/seenNotifications',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    requestPayment: builder.mutation({
      query: (credentials) => ({
        url: 'user/reqPayTimeline',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    invoice: builder.mutation({
      query: (credentials) => ({
        url: 'user/invoice',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getAvatars: builder.mutation({
      query: () => ({
        url: 'user/getAvatars',
        method: 'Get',
      }),
    }),
    rejectChange: builder.mutation({
      query: (credentials) => ({
        url: 'user/rejectTimeline',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    acceptChange: builder.mutation({
      query: (credentials) => ({
        url: 'user/changeTimeline',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    requestChange: builder.mutation({
      query: (credentials) => ({
        url: 'user/reqAddTimeline',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    updateEmail: builder.mutation({
      query: (credentials) => ({
        url: 'user/updateEmail',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    verifiedEmail: builder.mutation({
      query: (credentials) => ({
        url: 'user/verifiedEmail',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),

    hiidenHandler: builder.mutation({
      query: (credentials) => ({
        url: 'user/AcceptHiddenMessage',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),

    paySubscribe: builder.mutation({
      query: (credentials) => ({
        url: 'user/paySubscript',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    createSubscript: builder.mutation({
      query: (credentials) => ({
        url: 'user/createSubscript',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    createBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/createBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    editBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/editBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    deleteBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/deleteBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    showPackages: builder.mutation({
      query: (credentials) => ({
        url: 'user/showPackages',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    payBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/payBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    checkBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/payAvailableBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    updateCover: builder.mutation({
      query: (credentials) => ({
        url: 'user/updateCover',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    allBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/allBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    baxList: builder.mutation({
      query: (credentials) => ({
        url: 'user/boxes',
        method: 'Get',
        // body: {
        //   data: encrypt(credentials),
        // },
      }),
    }),
    withdrawAsJudge: builder.mutation({
      query: (credentials) => ({
        url: 'user/withdrawAsJudge',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    withdrawRewards: builder.mutation({
      query: (credentials) => ({
        url: 'user/withdrawRewards',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    rewards: builder.mutation({
      query: () => ({
        url: 'user/rewards',
        method: 'Get',
      }),
    }),
    withdrawBoxReward: builder.mutation({
      query: (credentials) => ({
        url: 'user/withdrawBoxReward',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    updateSubscription: builder.mutation({
      query: (credentials) => ({
        url: 'user/updateSubscription',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    detailOfPackage: builder.mutation({
      query: (credentials) => ({
        url: 'user/detailOfPackage',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    settingPage: builder.mutation({
      query: (credentials) => ({
        url: 'user/settingPage',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    disableBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/disableBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    signDeleteAcceptor: builder.mutation({
      query: (credentials) => ({
        url: 'user/signDeleteAcceptor',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    removeProposal: builder.mutation({
      query: (credentials) => ({
        url: `user/removeProposal/${credentials}`,
        method: 'Delete',
      }),
    }),
    PutInBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/PutInBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    DropBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/DropBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getBoxByBaseToken: builder.mutation({
      query: (credentials) => ({
        url: 'user/getBoxByBaseToken',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getBox: builder.mutation({
      query: (credentials) => ({
        url: 'user/getBox',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    createFund: builder.mutation({
      query: (credentials) => ({
        url: 'user/createFund',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    updateFund: builder.mutation({
      query: (credentials) => ({
        url: 'user/updateFund',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    revokeFund: builder.mutation({
      query: (credentials) => ({
        url: 'user/cancelFund',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getFund: builder.mutation({
      query: (credentials) => ({
        url: 'user/getFund',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    addFund: builder.mutation({
      query: (credentials) => ({
        url: 'user/addFund',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    withdrawFund: builder.mutation({
      query: (credentials) => ({
        url: 'user/withdrawFund',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    getFunds: builder.mutation({
      query: () => ({
        url: 'user/getFunds',
        method: 'Get',
      }),
    }),
    JoinRoom: builder.mutation({
      query: (credentials) => ({
        url: 'user/JoinRoom',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    createRoom: builder.mutation({
      query: (credentials) => ({
        url: 'user/createRoom',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    updateRoom: builder.mutation({
      query: (credentials) => ({
        url: 'user/updateRoom',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    addMessageRoom: builder.mutation({
      query: (credentials) => ({
        url: 'user/addMessageRoom',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    room: builder.mutation({
      query: (credentials) => ({
        url: 'user/room',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    AcceptRoomHiddenMessage: builder.mutation({
      query: (credentials) => ({
        url: 'user/AcceptRoomHiddenMessage',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
    rooms: builder.mutation({
      query: () => ({
        url: 'user/rooms',
        method: 'Get',
      }),
    }),
    getIconList: builder.mutation({
      query: () => ({
        url: 'user/getIconList',
        method: 'Get',
      }),
    }),
    getVersion: builder.mutation({
      query: () => ({
        url: 'user/version',
        method: 'Get',
      }),
    }),
    getCancelled: builder.mutation({
      query: () => ({
        url: 'user/cancelled',
        method: 'Get',
      }),
    }),
    withdrawCancelled: builder.mutation({
      query: (credentials) => ({
        url: 'user/cancelled',
        method: 'Post',
        body: {
          data: encrypt(credentials),
        },
      }),
    }),
  }),
});

export default userApi;
