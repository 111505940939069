import { configureStore } from '@reduxjs/toolkit';
import walletReducer from './Wallet/slice';
import userReducer from './User/slice';
import { userApi } from './api';
import disputeSlice from './Dispute/slice';
import hatchSlice from './Hatch/slice';
import complainSlice from './Complain/slice';
import adminSlice from './Admin/slice';
import SubscribeSlice from './Subscribe/slice';
import ChatSlice from './Chat/slice';

const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    walletSlice: walletReducer,
    userSlice: userReducer,
    dispute: disputeSlice,
    hatch: hatchSlice,
    complain: complainSlice,
    adminData: adminSlice,
    subscribe: SubscribeSlice,
    chatSlice: ChatSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userApi.middleware),
  devTools: false,
});

export default store;
