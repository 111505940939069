import './Assets/styles/tw.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './Assets/styles/global.css';

import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connectToMetaMask, connectToWalletConnect } from './Redux/Wallet/slice';
import { checkWallet } from './Pages/Auth/Utils/connect';
import { Toaster } from 'react-hot-toast';
import Notify from './Components/Toast';
import { useTranslation } from 'react-i18next';
import userApi from './Redux/api';
import { addSelected, getMe, getUser, loginUser, logout, setError, setLoading } from './Redux/User/slice';
import CreateProfile from './Pages/Profile/Component/Create';
import LoadingFull from './Components/Loading/LoadingFull';
import CreateHatch from './Pages/Hatch/Component/Create';
import { addSocket } from './utils/socket';
import ChoseModeModal from './Components/Modal/ChoseModeModal';
// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { axiosSetup } from './utils/axios.helper';
// -------------------- pages
// auth
const Auth = lazy(() => import('./Pages/Auth'));
const Login = lazy(() => import('./Pages/Auth/Component/login'));

// panel
const Layout = lazy(() => import('./Components/Layout'));
const Hatch = lazy(() => import('./Pages/Hatch'));
const Dispute = lazy(() => import('./Pages/Dispute'));
const Profile = lazy(() => import('./Pages/Profile'));
// const CreateProfile = import('./Pages/Profile/Component/Create'));
const Complaint = lazy(() => import('./Pages/Complaint'));
const HatchPage = lazy(() => import('./Pages/Hatch/Component/HatchInfo'));
const HatchOwnerPage = lazy(() => import('./Pages/Hatch/Component/Info.Owner'));
const Notification = lazy(() => import('./Pages/Hatch/Component/Notification'));
const Warning = lazy(() => import('./Pages/Hatch/Component/Warning'));
const Cancel = lazy(() => import('./Pages/Hatch/Component/Cancel'));
const RequestWarning = lazy(() => import('./Pages/Hatch/Component/Request.Warning'));
const RequestCancel = lazy(() => import('./Pages/Hatch/Component/Request.Cancel'));
const Payment = lazy(() => import('./Pages/Hatch/Component/Payment'));
const Complete = lazy(() => import('./Pages/Hatch/Component/Complete'));
const RequestComplete = lazy(() => import('./Pages/Hatch/Component/Request.Complete'));
const RequestPayment = lazy(() => import('./Pages/Hatch/Component/Request.Payment'));
const ChatHatch = lazy(() => import('./Pages/Hatch/Component/Chat'));
const CreateComplaint = lazy(() => import('./Pages/Complaint/Component/Create'));
const DefenseComplaint = lazy(() => import('./Pages/Complaint/Component/Defense'));
const InfoComplaint = lazy(() => import('./Pages/Complaint/Component/Info'));
const JoinPage = lazy(() => import('./Pages/Dispute/Component/Join'));
const DisputeInfo = lazy(() => import('./Pages/Dispute/Component/Dispute'));
const AcceptHatch = lazy(() => import('./Pages/Hatch/Component/Accept'));
const Users = lazy(() => import('./Pages/Users'));
const Judges = lazy(() => import('./Pages/Judges'));
const Offline = lazy(() => import('./Pages/Offline'));
const FAQ = lazy(() => import('./Pages/FAQ'));
const Subscribe = lazy(() => import('./Pages/Subscribe/page'));
const Explorer = lazy(() => import('./Pages/Subscribe/component/Explorer'));
const FeedBack = lazy(() => import('./Pages/Feedback/page'));
const Chat = lazy(() => import('./Pages/Chat/page'));
const AcceptChat = lazy(() => import('./Pages/Chat/Component/Accept'));
const Cancelled = lazy(() => import('./Pages/Subscribe/component/fundraising/Cancelled'));

function App() {
  const [type, setType] = useState('');
  const [offline, setOffline] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [login, {}] = userApi.useLoginMutation();
  const [apiInfo, {}] = userApi.useGetMeMutation();
  let navigate = useNavigate();
  let location = useLocation();

  // const firebaseConfig = {
  //   apiKey: 'AIzaSyBq-LI07bjaFmxaBnrBJmzSGMgz7okPoAQ',
  //   authDomain: 'openhatch-bbedd.firebaseapp.com',
  //   projectId: 'openhatch-bbedd',
  //   storageBucket: 'openhatch-bbedd.appspot.com',
  //   messagingSenderId: '368652505830',
  //   appId: '1:368652505830:web:a84b462d28b41acfb7a127',
  //   measurementId: 'G-46JZET4N42',
  // };

  const { success, error, wallet } = useSelector((state) => state.walletSlice);

  useEffect(() => {
    axiosSetup();
    // const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
  }, []);
  useEffect(() => {
    if (offline === false && wallet) {
      Notify({ duration: 3000, message: t('auth.Wallet_connected'), type: 'success' });
      addSocket(wallet);
      dispatch(setLoading());

      checkWallet(type);

      handelActions();
    }
  }, [wallet]);

  useEffect(() => {
    if (error) {
      // console.log({ error });
      Notify({ duration: 3000, message: error, type: 'error' });
    }
  }, [error]);
  useEffect(() => {
    const check = async () => {
      const data = window.localStorage.getItem('wallet');

      if (data) {
        const parseData = await JSON.parse(data);

        if (parseData.type) {
          switch (parseData.type) {
            case '1':
              dispatch(connectToMetaMask());
              setType('metamask');
              break;
            case '2':
              dispatch(connectToWalletConnect());
              setType('walletConnect');
              break;

            default:
              window.localStorage.removeItem('wallet');
              break;
          }
        }
      } else {
        window.localStorage.removeItem('wallet');
      }
    };

    if (offline === false) {
      check();
    }
  }, []);

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];

    const temp = localStorage.getItem('theme');

    if (temp) {
      if (temp === '2') {
        html.setAttribute('data-theme', 'light_theme');
      } else {
        html.setAttribute('data-theme', 'dark_theme');
      }
    }
  }, []);

  const handelActions = async () => {
    try {
      // get user if has token
      const temp = localStorage.getItem('mode');
      const token = window.localStorage.getItem('auth_login');
      if (temp) {
        dispatch(addSelected(temp));
      }
      if (token) {
        if (!temp) {
          setOpen(true);
          return;
        }
        await getUserInfo();
        if (!location.pathname.toLowerCase().includes('panel')) {
          navigate('/panel/profile');
        }

        return;
      }
      // login user if not
      let data = {
        base_token: `open_${wallet}_hatch`,
        code: '000000',
      };
      const loginData = await login(data).unwrap();

      dispatch(loginUser(loginData.data));

      if (!temp) {
        setOpen(true);
        return;
      }
      navigate('/panel');
      // }
    } catch (error) {
      // console.log(error);

      const temp = error.error ? error.error[0].message : error.message;

      dispatch(setError({ error: temp }));
    }
  };

  const getUserInfo = async () => {
    try {
      // login user if not

      const token = window.localStorage.getItem('auth_login');
      const token2 = window.localStorage.getItem('auth_login_');

      if (token && token2) {
        const response = await apiInfo().unwrap();

        dispatch(getMe({ user: response.data.user }));
      }

      // navigate('/panel/profile/create', {);
    } catch (error) {
      // console.log(error);

      const temp = error.error ? error.error[0].message : error.message;

      dispatch(setError({ error: temp }));

      navigate('/');
    }
  };
  return (
    <div className='App overflow-hidden'>
      <ChoseModeModal open={open} cancelBtn={() => setOpen(false)} />
      <Suspense fallback={<LoadingFull />}>
        {offline === false ? (
          <Routes>
            <Route path='/' element={<Auth />}>
              <Route index element={<Login />} />
            </Route>
            <Route path='/panel' element={<Layout />}>
              <Route path='profile' element={<Profile />} />
              <Route path='profile/create' element={<CreateProfile />} />

              <Route path='hatch' element={<Hatch />} />
              <Route path='hatch/create' element={<CreateHatch />} />
              <Route path='hatch/info' element={<HatchPage />} />
              <Route path='hatch/accept' element={<AcceptHatch />} />
              <Route path='hatch/info-owner' element={<HatchOwnerPage />} />
              <Route path='hatch/notification' element={<Notification />} />
              <Route path='hatch/warning' element={<Warning />} />
              <Route path='hatch/warning-req' element={<RequestWarning />} />
              <Route path='hatch/cancel' element={<Cancel />} />
              <Route path='hatch/cancel-req' element={<RequestCancel />} />
              <Route path='hatch/payment' element={<Payment />} />
              <Route path='hatch/payment-req' element={<RequestPayment />} />
              <Route path='hatch/complete' element={<Complete />} />
              <Route path='hatch/complete-req' element={<RequestComplete />} />
              <Route path='hatch/chat' element={<ChatHatch />} />
              <Route path='complaint' element={<Complaint />} />
              <Route path='complaint/create' element={<CreateComplaint />} />
              <Route path='complaint/defense' element={<DefenseComplaint />} />
              <Route path='complaint/info' element={<InfoComplaint />} />
              <Route path='dispute' element={<Dispute />} />
              <Route path='dispute/join' element={<JoinPage />} />
              <Route path='dispute/info' element={<DisputeInfo />} />
              <Route path='faq' element={<FAQ />} />
              <Route path='subscribe' element={<Subscribe />} />
              <Route path='subscribe/explorer' element={<Explorer />} />
              <Route path='subscribe/cancelled' element={<Cancelled />} />
              <Route path='chat' element={<Chat />} />
              <Route path='chat/join' element={<AcceptChat />} />
            </Route>
            <Route path='/adminPanel' element={<Layout />}>
              <Route path='users' element={<Users />} />
              <Route path='request_judge' element={<Judges />} />
              <Route path='feedback' element={<FeedBack />} />
            </Route>
          </Routes>
        ) : (
          <Offline />
        )}
      </Suspense>

      <Toaster position='top-right' />
    </div>
  );
}

export default App;
