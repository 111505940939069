import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  selected: null,
  loading: false,
  error: null,
  show: false,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    getAll: (state, actions) => {
      const { data } = actions.payload;

      state.list = data;
      state.loading = false;

      state.error = null;
      state.show = false;
    },
    getOne: (state, actions) => {
      const data = actions.payload;

      state.selected = data;
      state.loading = false;

      state.error = null;
      state.show = true;
    },
    cancel: (state) => {
      state.selected = null;
      state.loading = false;

      state.error = null;
      state.show = false;
    },
    setError: (state, actions) => {
      const { error } = actions.payload;
      state.loading = false;

      state.error = error;
      state.show = false;
    },
    setLoading: (state) => {
      state.loading = true;
    },
  },
});

export const { getAll, getOne, setError, setLoading, cancel } = adminSlice.actions;

export default adminSlice.reducer;
