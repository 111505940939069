import WalletConnectProvider from '@walletconnect/web3-provider';

const providerConfig = {
  bridge: 'https://bridge.walletconnect.org',
  rpc: {
    1: 'https://mainnet.infura.io/v3/975a24f4b0df4237bfac690cef2ad74c',
  },
  chainId: 1,
  // clientMeta: {
  //   description: ' web3 connection',
  //   url: 'https://nodejs.org/en/',
  //   icons: ['https://nodejs.org/static/images/logo.svg'],
  //   name: 'hatch pos',
  // },
};

export let provider = new WalletConnectProvider(providerConfig);

export const checkWalletConnectProvider = () => {
  if (!provider) {
    provider = new WalletConnectProvider(providerConfig);
  }
};

export const openWalletConnect = async () => {
  if (!provider) {
    provider = new WalletConnectProvider(providerConfig);
  }
  return await provider.enable();
};

export const getWalletConnect = () => {
  return provider;
};

export const removeWalletConnect = () => {
  provider = null;
};

export const signMessage = async (m, p) => {
  if (provider) {
    try {
      const customRequest = { jsonrpc: '2.0', method: 'eth_signTypedData_v4', params: p };

      const res = await provider.send(customRequest);

      return res;
    } catch (error) {
      // console.log(error);
      return null;
    }
  } else {
    return null;
  }
};

export const disconnectWalletConnect = async () => {
  if (provider) {
    await provider.disconnect();
    provider = null;
  }
};
